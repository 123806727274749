import { Router, ReactLocation, Outlet } from "@tanstack/react-location";
import { Toaster } from "react-hot-toast";
import { routes } from "./configs/routes";
import HeaderNavigation from "./components/HeaderNavigation";
import UnauthorizedDialog from "./components/dialog/401.dialog";
import { useContext, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useAuthCheckpoint } from "./hooks/useAuthCheckpoint";
import { setAuthorizationHeader as setManagementAuthorizationHeader } from "./configs/api-auth0-management.config";
import { setAuthorizationHeader as setTimeHiveAuthorizationHeader } from "./configs/api-timehive.config";
import { getAccessToken as getAuth0ManagementAccessToken } from "./services/requests/auth.auth0.request";
import NoTenantDialog from "./components/dialog/NoTenant.dialog";
import LoadingTenantPage from "./pages/LoadingTenantPage";
import { useAuth0User } from "./services/queries/user.auth0.query";
import { type TLanguage, LanguageContext } from "./context/LanguageContext";
import translations from "./helpers/translations";
import CustomJoyrideWrapper from "./components/tutorial/CustomJoyrideWrapper";
import packageJson from "../package.json";
import CacheBuster from "react-cache-buster";
import GlobalAlerts from "./components/GlobalAlerts";

const location = new ReactLocation();

function App(): JSX.Element {
  const { getAccessTokenSilently, isLoading, isAuthenticated, user } = useAuth0();
  const { data: auth0UserData } = useAuth0User(user?.sub ?? "");
  const authCheckpoint = useAuthCheckpoint();

  const languageContext = useContext(LanguageContext);

  useEffect(() => {
    const getWordFromURL = () => {
      const url = window.location.href;
      const parts = url.split("/");
      const word = parts[parts.length - 1];
      return word;
    };

    const setCookie = (name: string, value: string, days: number) => {
      const expires = new Date();
      expires.setTime(expires.getTime() + days * 24 * 60 * 60 * 1000);
      document.cookie = `${name}=${value};expires=${expires.toUTCString()};path=/`;
    };

    const word = getWordFromURL();

    if (word && !word.includes("?")) {
      setCookie("HHAXchange-tenant-id", word, 100); // Change the expiration time as needed
    }
    if (!isLoading && isAuthenticated) {
      getAccessTokenSilently({
        authorizationParams: {
          audience: window._env_.REACT_APP_TIMEHIVE_API_AUDIENCE,
        },
      })
        .then((res) => {
          authCheckpoint?.setAuth0TimeHive({
            accessToken: res,
            isAuthenticated: true,
          });
          setTimeHiveAuthorizationHeader(res);
        })
        .catch(console.error);
    }
  }, [getAccessTokenSilently, isLoading, isAuthenticated]);

  useEffect(() => {
    if (!isLoading) {
      getAuth0ManagementAccessToken()
        .then((res) => {
          authCheckpoint?.setAuth0Management({
            accessToken: res.access_token,
            isAuthenticated: true,
          });
          setManagementAuthorizationHeader(res.access_token);
        })
        .catch(console.error);
    }
  }, [getAuth0ManagementAccessToken, isLoading, isAuthenticated]);

  useEffect(() => {
    const userSpecifiedLanguage = auth0UserData?.user_metadata?.preferredLanguage;
    if (
      userSpecifiedLanguage &&
      translations[userSpecifiedLanguage as keyof typeof translations] &&
      userSpecifiedLanguage !== languageContext?.language
    ) {
      languageContext?.setLanguage(userSpecifiedLanguage as TLanguage);
    }
  }, [user]);

  return (
    <CacheBuster
      currentVersion={packageJson.version}
      isEnabled={window._env_.REACT_APP_ENVIRONMENT === "Production"}
      loadingComponent={<LoadingTenantPage />}
    >
      <>
        <GlobalAlerts />
        <Toaster position='top-right' />
        <Router location={location} routes={routes}>
          <HeaderNavigation />
          <div className='divider' />
          <Outlet />
          <NoTenantDialog />
          <CustomJoyrideWrapper />
        </Router>
        <UnauthorizedDialog />
      </>
    </CacheBuster>
  );
}

export default App;
