import informationReducer, { INFORMATION_SLICE_NAME } from "./features/information.slice";
import { combineReducers } from "@reduxjs/toolkit";
import dateTimeFormReducer, { DATE_TIME_FORM_SLICE_NAME } from "./features/date-time-form.slice";
import signatureReducer, { SIGNATURE_SLICE_NAME } from "./features/signature.slice";
import {
  INCOMPLETE_VISIT_SLICE_NAME,
  incompleteVisitReducer,
} from "./features/incomplete-visit.slice";
import { HAS_ERROR_SLICE_NAME, hasErrorReducer } from "./features/has-error.slice";
import { CAREGIVER_INFO_SLICE_NAME, caregiverInfoReducer } from "./features/caregiver-info.slice";
import { APP_INFO_SLICE_NAME, appInfoReducer } from "./features/app-info.slice";

import taskReducer, { TASKS_SLICE_NAME } from "./features/tasks.slice";

const rootReducer = combineReducers({
  // Add your reducers here
  [TASKS_SLICE_NAME]: taskReducer,
  [INFORMATION_SLICE_NAME]: informationReducer,
  [DATE_TIME_FORM_SLICE_NAME]: dateTimeFormReducer,
  [SIGNATURE_SLICE_NAME]: signatureReducer,
  [INCOMPLETE_VISIT_SLICE_NAME]: incompleteVisitReducer,
  [HAS_ERROR_SLICE_NAME]: hasErrorReducer,
  [CAREGIVER_INFO_SLICE_NAME]: caregiverInfoReducer,
  [APP_INFO_SLICE_NAME]: appInfoReducer,
});

export default rootReducer;
