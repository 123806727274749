import { useAppDispatch, useAppSelector } from "../../redux/store";
import { useCookies } from "react-cookie";
import { IoIosHelpCircle, IoIosHelpCircleOutline } from "react-icons/io";
import { setPartialAppInfoAction } from "../../redux/features/app-info.slice";
import { tutorialCookieName } from "../../helpers/constant/cookies";

const iconSize = 30;

const TutorialTrigger = () => {
  const tutorialRunning = useAppSelector((state) => state.app_info.tutorialRunning);
  const dispatch = useAppDispatch();
  const [, setCookie] = useCookies();

  const handleToggleTutorial = () => {
    setCookie(tutorialCookieName, !tutorialRunning);
    dispatch(
      setPartialAppInfoAction({
        tutorialRunning: !tutorialRunning,
        tutorialIndex: location.pathname === "/" ? 0 : location.pathname === "/visits" ? 1 : 5,
      }),
    );
  };

  return (
    <button onClick={handleToggleTutorial} style={{ zIndex: 1001 }} className='navbar-end'>
      {tutorialRunning ? (
        <>
          <IoIosHelpCircle color='#cd3d84' size={iconSize} />
          <IoIosHelpCircle
            color='#cd3d84'
            className='animate-ping absolute inline-flex rounded-full opacity-75'
            size={iconSize}
          />
        </>
      ) : (
        <IoIosHelpCircleOutline size={iconSize} />
      )}
    </button>
  );
};

export default TutorialTrigger;
