import { type ITranslation } from "../../types/types";

const cebuano: ITranslation = {
  tutorial: {
    stepOne: {
      newUsers: () => "Bag-ong Mga User",
      existingUsers: () => "Mga Eksisting nga User",
      clickOnGetStarted: () =>
        'Pagsikop sa "Mag-umpisa" magdala kanimo sa among serbisyo sa autentikasyon, Auth0.',
      newUserNextSteps: () =>
        `Human mag-sign up, ang Auth0 magadirekta kanimo balik ngadto sa TimeHive.`,
      existingUserNextSteps: () =>
        `Human mamugna, ang Auth0 magadirekta kanimo balik ngadto sa TimeHive. Kon adunay na ka mabuhat, diretso ka dayon nga idirekta ngadto sa TimeHive.`,
    },
    clickIcon: () => "Klik sa imong icon aron mubukas ang usa ka dropdown nga menu.",
    useButtons: () =>
      "Gamita ang mga buton aron tabangan ka sa pagpili sa mga bisita nga imong gusto i-kumpirma.",
    clickToSelectVisits: () => "Klik aron pili-on ang mga bisita nga imong gusto ikumpirma.",
    proceedConfirm: () => "Padayon sa pagkumpirma sa gipili nga mga bisita.",
    onlyRedAsterixRequired: () =>
      "Ang mga kampo nga walay pula nga asterisk dili kinahanglanon ug mahimo walay sulod.",
    verifyYourInfo: () =>
      "Tsekaha nga tama ang imong impormasyon. Kontakaha ang usa ka administrator alang sa mga kausaban.",
    verifyPatientInfo: () =>
      "Tsekaha nga tama ang impormasyon sa pasyente. Kontakaha ang usa ka administrator alang sa mga kausaban.",
    verifyPatientAddressAndId: () => "Tsekaha o isulat ang address ug ID sa pasyente.",
    verifyDateTimes: () => "Tsekaha ang petsa ug oras sa pagbisita.",
    checkDuties: () =>
      "Tsekaha ang mga katungdanan nga imong gihimo alang sa matag adlaw (bisita) nga imong gikumpirmahan. Depende sa imong ahensya, kini mahimo o dili makita.",
    mayNotBeVisible: () => "Depende sa imong ahensya, kini mahimo o dili makita.",
    provideSignatures: () => "Hatagi ug lagda ang pasyente ug ikaw mismo.",
    checkDaysUnableToSign: () =>
      "Tsekaha, ug ipadayag ang rason alang sa bisan unsang adlaw diin ang pasyente dili makalagda.",
    clickSubmitData: () =>
      "Klik aron isumite ang imong gipasulod nga datos ug kumpirmahon ang imong mga bisita.",
    downloadPDF: () =>
      "Iblihi kini nga na-check aron makuha ang usa ka kopya sa PDF sa imong oras nga tambalan.",
    finalSubmit: () =>
      "Walay mubalik! Klik diri aron ipadala ang imong datos ug kumpirmahon ang imong bisita.",
  },
  twoCharCode: () => "ce",
  language: () => "Lengguwahe",
  languages: {
    eng: () => "Ininglis",
    spa: () => "Espanyol",
    rus: () => "Ruso",
    kor: () => "Koreano",
    yue: () => "Cantonese",
    cmn: () => "Mandarin",
    ben: () => "Bengali",
    ceb: () => "Cebuano",
    yid: () => "Yiddish",
  },
  navigation: {
    home: () => "Sinugdan",
    visits: () => "Mga Pagduaw",
  },
  dropdown: {
    aboutToLeave: () => "Hunong sa, gabiya naka!",
    confirmLogout: () => "Sigurado ba ka nga gusto ka mag-logout?",
  },
  actions: {
    ok: () => "Ok",
    cancel: () => "Kanselahon",
    clear: () => "Hawanan",
    close: () => "Isara",
    remove: () => "Kuhaa",
    submit: () => "Ipasa",
    save: () => "Ihipos",
    savingTrailOff: () => "Ginahipos...",
    update: () => "Update",
    updatingTrailOff: () => "Ginaupdate..",
    confirm: () => "Kumpirmahon",
    submittingTrailOff: () => "Ginapasa...",
    sync: () => "Sync",
    syncTrailOff: () => "Nagsisync...",
    change: () => "Ilisan",
    back: () => "Balik",
    done: () => "Tapos na",
    next: () => "sunod",
  },
  welcomeToTimeHive: () => "Malipayong Pag-abot sa TimeHive",
  welcomeToTimeHivePleaseUpdateInfo: () =>
    "Malipayong Pag-abot sa TimeHive, Palihug usba ang inyong impormasyon sa ubos aron makapadayon",
  completeYourVisits: () => "Kompletuha ang imong mga Pagduaw",
  getStarted: () => "Sugdi",
  noteExclamation: () => "Pahibalo!",
  noSensitiveDataSaved: () =>
    "Wala kami nagtipig ug sensitibong personal nga mga impormasyon. Ang musunod gigamit aron mailhan sa inyong profile sa among EHR system.",
  resetSSN: () => "I-reset ang SSN",
  switchProfile: () => "Balhin ug Ahensya",
  selectProfile: () => "Pili-a ang Ahensya",
  noProfiles: () => "Wala'y Ahensya",
  noIncompleteVisits: () => "Wala'y dili Kompleto nga mga Pagduaw",
  login: () => "Login",
  loginToContinue: () => "Login aron makapadayon karon.",
  reload: () => "I-reload",
  logout: () => "Logout",
  youAreLoggedOut: () => "Naka-logout ka!",
  loginAgainToUse: () => "Mag-login pag-usab aron gamiton ang Timesheet",
  caregiverId: (caregiverId: string) => `Caregiver ID: ${caregiverId}`,
  "timesheets/days": (number?: number) => `${number ?? ""} timesheets/days`,
  redirecting: () => "Gipang-redirect",
  redirectingWithExclamation: () => "Gipang-redirect!",
  sortByDay: () => "Ihan-ay pinaagi sa Adlaw",
  selectByWeek: () => "Pili-a pinaagi sa Semana",
  updateSelectedVisits: (numberOfVisits: number) =>
    `E-update ang ${numberOfVisits} nga napilian nga Duaw`,
  fetchingVisits: () => "Gakuhag mga Pagduaw",
  myIncompleteVisits: () => "Akong mga dili Kompleto'ng Duaw",
  patientWithName: (patientName: string) => `Pasyente: ${patientName}`,
  visitDateWithDate: (visitDate: string) => `Petsa sa Duaw: ${visitDate}`,
  timeVisitStarted: (time: string) => `Oras sa Pagsugod sa Duaw: ${time}`,
  timeVisitEnded: (time: string) => `Oras sa Pagtapos sa Duaw: ${time}`,
  notProvided: () => "Wala gihatag",
  totalAccumulatedTimeWithColon: () => `Mga Natigom nga mga Oras:`,
  hoursShort: () => "hr",
  minutesShort: () => "min",
  theHiveAwaits: () => "Ang Hive naghuwat",
  noTenantPleaseCheckLink: () =>
    "Wala’y tenant nga nakit-an. Palihug beripikar sa link sa inyong department sa IT.",
  waitToLoadTenant: () => "Palihug maghulat samtang nag-load sa inyong tenant...",
  noSignatureFound: () => "Walay pirma nga nakit-an",
  typeHere: () => "I-type diri",
  sorryTryAgain: () => "Sorry, suwayi pag-usab",
  dialogs: {
    escapeOrClickButtonToClose: () =>
      "Pindota ang ESC nga key o i-klik ang buton sa ubos aron isira",
    unauthorized: {
      warningUnauthorized: () => "Bantay: Wala’y Lisensya nga Pag-access!",
      details: () =>
        "Wala ka gilisensyahan sa pag-adto niining pahina. Palihug kontakta ang inyong IT alang sa suporta.",
    },
    noTenant: {
      warningNoTenantFound: () => "Bantay: Walay Nakit-an nga Tenant sa URL!",
      details: () =>
        "Kinahanglan may tukuron nga tenant sa pagpadayon. Palihug beripikar ang link sa inyong team sa IT.",
      enterIdentifier: () => "Ipasulod ang ID alang sa tenant",
    },
    resetSSN: {
      ssn: () => "SSN",
      userIDNotFound: () => "Walay Nakit-an nga User ID",
    },
    submitTimesheetOptions: {
      instructionsToSubmit: {
        part1: () => "Kon ikaw moklik sa ",
        part2: () => "Kumpirmahon",
        part3: () => ", ang imong timesheet mahimong ipadala.",
      },
      somethingWentWrong: () => "Oops! Naa’y di maayong nahitabo sa pagsu-submit.",
      errorReference: (errorCode: string) => `Reference#: ${errorCode}`,
      downloadPDF: () => "Download ug kopya sa PDF?",
      errorGeneratingPDF: () => "Sayop sa pag generate sa PDF. \nPalihug suwayi'g usab.",
      pleaseSelectPatient: () => "Palihug pili og pasyente",
      noMoreThan7Days: () => "Moka-submit ra ka ug 7 ka adlaw sa usa ka higayon",
      caregiverCodeRequired: () => "Kinahanglan ang numero sa empleyado",
      unableToGeneratePDF: () => "Dili makagenerate sa Timesheet PDF",
      areYouSureTheDataYouEnteredIsCorrect: () =>
        "Sigurado ka nga ang imong gipasulod nga data tama?",
    },
  },
  days: {
    sunday: () => "Domingo",
    monday: () => "Lunes",
    tuesday: () => "Martes",
    wednesday: () => "Miyerkules",
    thursday: () => "Huwebes",
    friday: () => "Biyernes",
    saturday: () => "Sabado",
  },
  timesheet: {
    xDutiesAreRequiredInThisSection: (minimum) =>
      `Sa kini nga bahin, kinahanglan ang diha ${minimum.toString()} ka mga katungdanan`,
    checkOffDaysPatientCannotSign: () =>
      "Please check off all days when the patient could not sign",
    hhaDutyForm: () => "HOME HEALTH AIDE DUTY FORM",
    patientSignature: () => "Pirma sa Pasyente",
    hhaSignature: () => "Pirma sa Home Health Aide",
    signHere: () => "Pirma Diri",
    patientUnableToSign: () => "PASYENTE DILI MAKAPIRMA",
    whyPatientCannotSign: () => "Palihug tukura nganong dili makapirma ang pasyente",
    whyWithQuestionMark: () => "Ngano?",
    noDuplicateDaysOrDates: () => "Dili tugma ang mga parehong adlaw o petsa",
    pleaseFillAllFields: () => "Palihug pun-a ang tanang gikinahanglan",
    atLeastOneLine: () => "Kinahanglan ang usa ka linya.",
    atLeastOneVisit: () => "Palihug pili ug hinungdanon nga duaw aron ipasa.",
    dateAndTime: () => "PETSA & ORAS",
    timeCannotBeInFuture: () => "Ang oras dili pweding umaabot nga panahon",
    endingDayOfWeek: (endingDayOfWeek: string) => `Nahuman ang ${endingDayOfWeek}`,
    date: () => "Petsa",
    day: () => "Adlaw",
    timeArrived: () => "Oras sa Pag-abot sa Pasyente",
    timeLeft: () => "Oras sa Pagbiya sa Pasyente",
    totalHours: () => "Mga oras nga gipangtrabaho",
    overnightShiftWithQuestionMark: () => "Tibuok gabii nga shift?",
    scheduledTime: (time: string) => `Gitakdang Oras : ${time}`,
    duties: () => "MGA TUGON",
    checkOffDuties: () => "Palihug e-check tanan nga na-kumpleto nga mga Tugon",
    information: () => "IMPORMASYON",
    caregiverName: () => "Ngalan sa Empleyado",
    socialSecurityNumber: () => "Social Security Number",
    caregiverCode: () => "Numero sa Empleyado",
    patientName: () => "Ngalan sa Pasyente",
    phone: () => "Telepono",
    year: () => "Tuig",
    patientAddress: () => "Address sa Pasyente",
    patientId: () => "ID sa Pasyente",
    timesheetReason: () => "Rason sa Timesheet",
    reason: () => "Rason",
    select: () => "Pagpili",
    missing: {
      hhaSignature: () => "Walay Pirma sa HHA.",
      patientSignature: () => "Walay  Pirma sa Pasyente.",
      patientId: () => "Walay ID sa Pasyente.",
      patientName: () => "Walay Ngalan sa Pasyente.",
      patientAddress: () => "Walay address sa Pasyente.",
      caregiverName: () => "Walay Ngalan sa Empleyado.",
      caregiverCode: () => "Walay Numero sa Empleyado.",
      pocCompliance: () => "Mga gidapit nga katungdanan dili gisusi / POC Compliance",
      timesheetReason: () => "Wala ang Timesheet Rason.",
    },
    signInBoxBelow: () => "Palihug pirma sa kahon sa ubos",
  },
  errors: {
    caregiverIdRequired: () => "Kinahanglan ang Caregiver ID",
    firstNameRequired: () => "Kinahanglan ang First name",
    lastNameRequired: () => "Kinahanglan ang Last name",
    ssnRequired: () => "Kinahanglan ang SSN",
    dayRequired: () => "Kinahanglan ang Adlaw",
    dateRequired: () => "Kinahanglan ang Petsa",
    timeCannotBeInTheFuture: () => "Dili pwede ang Oras sa Umaabot nga Panahon",
    totalHrsRequired: () => "Kinahanglan ang Total oras nga gi-trabaho",
    greaterThan0: () => "Kinahanglan nga mas dako pa kaysa 0 ang total oras nga gi-trabaho",
    lessThan24hrs: () => "Kinahanglan dili molapas sa 24 oras ang total nga gi-trabaho.",
    mustBeInteger: () => "Kinahanglan nga tibuok ang total oras nga gi-trabaho",
    ssnFormat: () => "Ang SSN kinahanglan subod sa format nga 123-45-6789",
    ssnMustBe9Digits: () => "Ang SSN kinahanglan nga 9 ka digits lamang",
    caregiverNameRequired: () => "Kinahanglan ang ngalan sa Empleyado",
    caregiverCodeRequired: () => "Kinahanglan ang numero sa Empleyado",
    patientNameRequired: () => "Kinahanglan ang ngalan sa Pasyente",
    invalidYear: () => "Dili balido'ng tuig",
    patientAddressRequired: () => "Kinahanglan ang address sa Pasyente",
    patientIdRequired: () => "Kinahanglan ang ID sa Pasyente",
    invalidPatientId: () => "Dili balido'ng Patient ID",
    phoneNumberRequired: () => "Kinahanglan ang Numero sa Telepono",
  },
  mainainance: {
    maintainanceInProgress: () => "Maintainance sa Progreso",
    weAreApplyingUpgradesOperationalSoon: () =>
      "Ang Hive nagaplinyo ug nagapahibalo, kita mag-operational sa dali",
    thankYouForYourPatience: () => "Salamat sa inyong pasensya",
    maintainanceWillBePerformed: (startTime, endTime) =>
      "Ang TimeHive dili operasyonal alang sa schedule nga maintainance taliwala sa " +
      startTime +
      " ug " +
      endTime,
  },
};

export default cebuano;
