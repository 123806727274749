import { type VisitsType } from "../../services/schemas/visit.schema";
import { type PayloadAction, createSlice } from "@reduxjs/toolkit";

export const INCOMPLETE_VISIT_SLICE_NAME = "incomplete_visit";

interface IIncompleteVisitState {
  selectedVisits: VisitsType;
}

const initialState: IIncompleteVisitState = {
  selectedVisits: [],
};

const incompleteVisitSlice = createSlice({
  name: INCOMPLETE_VISIT_SLICE_NAME,
  initialState,
  reducers: {
    setSelectedVisits: (state, action: PayloadAction<IIncompleteVisitState["selectedVisits"]>) => {
      state.selectedVisits = action.payload;
    },
  },
});

export const { setSelectedVisits: setSelectedVisitsAction } = incompleteVisitSlice.actions;

export const incompleteVisitReducer = incompleteVisitSlice.reducer;
