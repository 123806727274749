import { type ITranslation } from "../../types/types";

const yiddish: ITranslation = {
  tutorial: {
    stepOne: {
      newUsers: () => "נייע משתמשים",
      existingUsers: () => "באקאנדיקטע ניצער",
      clickOnGetStarted: () =>
        'ווען איר קליקט אויף "געט סטארטעד" וועט עפּרייטן אייך צו אונדזער אוטענטיקאַטיאָן סערוויס, Auth0.',
      newUserNextSteps: () => `נאך דער הײַנטיקן, אוית0 וועט דיר רידירעקט אייך צוריק צו טיימהייוו.`,
      existingUserNextSteps: () =>
        `נאך דער פֿארלאגן, אוית0 וועט דיר רידירעקט אייך צוריק צו טיימהייוו. אויב איר זענט שוין איינגעלאַגן, איר ווערט גלייך רידירעקט צוריק צו טיימהייוו.`,
    },
    clickIcon: () => "קליק אויף דײַן איקאָן צו עפענען אַ דראָפּדאָון מעניע.",
    useButtons: () => "נוצן די קנעפֿל אַרויף דיר צו העלפֿן אין די פּעטערס צו קאָנפֿירמען.",
    clickToSelectVisits: () => "קליק צו סעלעקטירן בעזוכט בעזוכט וואס איר וואַנט צו קאָנפֿירמען.",
    proceedConfirm: () => "פֿאָרטזעצן צו באַשטעטיקן סעלעקטירט בעזוכט.",
    onlyRedAsterixRequired: () =>
      "פעלדער אויבער אָהן רויטע שטערן זענען נישט פֿאַרלאָנגען און קענען זיין ליידיק.",
    verifyYourInfo: () =>
      "וועריפֿיצירן אַז דיין אינפֿאָרמאַציע איז ריכטיק. קאָנטאַקט אַ אַדמיניסטראַטאָר פֿאַר ענדערונגען.",
    verifyPatientInfo: () =>
      "וועריפֿיצירן אַז דיין פּאַטיענט אינפֿאָרמאַציע איז ריכטיק. קאָנטאַקט אַ אַדמיניסטראַטאָר פֿאַר ענדערונגען.",
    verifyPatientAddressAndId: () => "וועריפֿיצירן אָדער איינגעבן די פּאַטיענט'ס אַדרעס און די ID.",
    verifyDateTimes: () => "וועריפֿיצירן די דאַטע און צייט פֿון די בעזוכט.",
    checkDuties: () =>
      "וועריפֿיצירן די דיוטיס איר אראָפֿן פֿאַר יעדן טאָג (בעזוכט) איר זענען קאָנפֿירמינג. אַבהענגיק אויף דײַן אַגענץ, דעם קענען זיין געזעהן אָדער נישט.",
    mayNotBeVisible: () => "אַבהענגיק אויף דײַן אַגענץ, דעם קענען זיין געזעהן אָדער נישט.",
    provideSignatures: () => "געבן אַ שיין פֿאַר די פּאַטיענט און פֿאַר זיך אָנהייבעט.",
    checkDaysUnableToSign: () =>
      "קאָנטראָלירן אָפּ, און אַרייַנגעבן די גרונט פֿאַר ניישט קיינמאָל טעג ווו די פּאַטיענט האט נישט קיינמאָל דעפֿינירן.",
    clickSubmitData: () =>
      "קליק צו אַרייַנשיקן די דאַטע איר האָבן געגעבן און קאָנפֿירמען דיין בעזוכט.",
    downloadPDF: () =>
      "לאָזן דאָוונלאָוד דעם געפֿיל צו דאָונלאָוד אַ פּדף קאָפּיע פֿון דײַן צייט רעקאָרד.",
    finalSubmit: () =>
      "טאָן קיין צוריק! קליק איר איקס איר דיין דאַטן אָפּ און קאָנפֿירמינג דיין בעזוכט.",
  },
  twoCharCode: () => "yi",
  language: () => "שפּראַך",
  languages: {
    eng: () => "ענגליש",
    spa: () => "שפּאַניש",
    rus: () => "רוסיש",
    kor: () => "קאָרעאַניש",
    yue: () => "קאַנטאָנעזיש",
    cmn: () => "מאַנדאַריניש",
    ben: () => "בענגאַליש",
    ceb: () => "סעבואַנאָ",
    yid: () => "ייִדיש",
  },
  navigation: {
    home: () => "היים",
    visits: () => "באַזוכט",
  },
  dropdown: {
    aboutToLeave: () => "!אויבן, איר זענט אין באַגריף צו גיין",
    confirmLogout: () => "?זענט איר זיכער איר ווילן ראָג אָוט",
  },
  actions: {
    ok: () => "אָקיי",
    cancel: () => "אַוואָסטרייכן",
    clear: () => "קלאָר",
    close: () => "פאַרשליסן",
    remove: () => "וועקהאַלטן",
    submit: () => "ארויפשיקן",
    save: () => "שפּעיכערן",
    savingTrailOff: () => "...שפּעיכערן",
    update: () => "אַפּדייטן",
    updatingTrailOff: () => "...אַפּדייטן",
    confirm: () => "באַשטעטיקן",
    submittingTrailOff: () => "...ארויפשיקן",
    sync: () => "סינכראָניזירן",
    syncTrailOff: () => "...סינכראָניזירן",
    change: () => "ענדערן",
    back: () => "צוריק",
    done: () => "אַרײַן",
    next: () => "נעקסט",
  },
  welcomeToTimeHive: () => "ברוכים הבאים צו צייט הייוו",
  welcomeToTimeHivePleaseUpdateInfo: () =>
    "ברוכים הבאים צו צייט הייו, ביטע אַפּדייטן אייער אינפֿאָרמאַציע למטה צו קאָנטינאָאָר",
  completeYourVisits: () => "פאַרפֿירן דיין באַזוכטן",
  getStarted: () => "אָנהייבן",
  noteExclamation: () => "!הערה",
  noSensitiveDataSaved: () =>
    "מיר ריטן נישט פּערזאַנל דאַטען. דעם אינפֿאָרמאַציע וועט זיין געניצט צו ידער פּראָפּיל אין אונדזערער ער סיסטעם אין אונדזערע עהר-סיסטעם",
  resetSSN: () => "רעסעט שונען",
  switchProfile: () => "ענדערן צווישען",
  selectProfile: () => "אויסקלייַבן צווישען",
  noProfiles: () => "קיין צווישען",
  noIncompleteVisits: () => "קיין פאַרקלערן באַזוכטן",
  login: () => "לאָג אין",
  loginToContinue: () => "לאָג אין צו קאָנטינאָאָר איצט",
  reload: () => "רילאָוד",
  logout: () => "אויסלאָגן",
  youAreLoggedOut: () => "איר זענט אויסלאָגגעלאָגט!",
  loginAgainToUse: () => "לאָג אין אַן אויס צו ניצן צייט שטוב",
  caregiverId: (caregiverId: string) => `${caregiverId} :פֿארארבעטער דער יאָלע`,
  "timesheets/days": (number?: number) => `מאָלע/טעג ${number ?? ""} `,
  redirecting: () => "ווייטערפירן",
  redirectingWithExclamation: () => "ווייטערפירן!",
  sortByDay: () => "זארטיילן נאָך טאָג",
  selectByWeek: () => "אויסקלייַבן נאָך וואָך",
  updateSelectedVisits: (numberOfVisits: number) => `אַפּדייטן ${numberOfVisits} סעלעקטירט באַזוכט`,
  fetchingVisits: () => "פעטשינג באַזוכטן",
  myIncompleteVisits: () => "מיין פאַרקלערן באַזוכטן",
  patientWithName: (patientName: string) => `${patientName} :פּאַטיענט`,
  visitDateWithDate: (visitDate: string) => `${visitDate} :באַזוכט טאָג`,
  timeVisitStarted: (time: string) => `${time} :זיי נעמען געזיין אַז אין אַלע האַנט`,
  timeVisitEnded: (time: string) => `${time} :זיי געזיין גענומען צייט`,
  notProvided: () => "נישט געשטעלט",
  totalAccumulatedTimeWithColon: () => `געסאַמעלטע צייט:`,
  hoursShort: () => "שעות",
  minutesShort: () => "מינוטעס",
  theHiveAwaits: () => "דער הייוו ווארט",
  noTenantPleaseCheckLink: () =>
    "קיין צווישען געפֿונען. ביטע פּריפֿן די לינק מיט אייער איט אויס טים",
  waitToLoadTenant: () => "...ביטע סטאַנד בײַ דורך ווען מיר לאָדן אייער צווישען",
  noSignatureFound: () => "קיין האַנטשריפֿט געפֿונען",
  typeHere: () => "שרייבן דאָ",
  sorryTryAgain: () => "סאָרי, פּרובירן עס אַנדערש",
  dialogs: {
    escapeOrClickButtonToClose: () => "דרוקט עסק מקש ESC אָדער קליק די קנעפֿל צו שליסן",
    unauthorized: {
      warningUnauthorized: () => "ווארנונג!: פֿאַרבאַטן צוטריט",
      details: () =>
        "איר זענט ניט אַוטאָריזירט צו צוטריט דעם בלאַט. ביטע קאָנטאַקטירט אײַער איַטי פֿאַר שטיצונג",
    },
    noTenant: {
      warningNoTenantFound: () => "!URL ווארנונג: קיין דיורעמאַן געפֿונען אין די",
      details: () =>
        "אַ דיורעמאַן מוזן זיין ספּעציפיצירט פֿאַרן פּאַסירן. ביטע פֿערגלייך די לינק מיט אײַער אייַ טעאַם",
      enterIdentifier: () => "אריין אידענטיפיקאַטאָר פֿאַר מייד",
    },
    resetSSN: {
      ssn: () => "SSN",
      userIDNotFound: () => "משתמש אידעע נישט געפֿונען",
    },
    submitTimesheetOptions: {
      instructionsToSubmit: {
        part1: () => "ווען איר קליקט",
        part2: () => "באַשטעטיקן",
        part3: () => "דיין טיימשיט וועט זיין פּאָסטעד",
      },
      somethingWentWrong: () => "אפס! אַן עררער איז געגאַנגען דירינג סובמישן.",
      errorReference: (errorCode: string) => `${errorCode} :#רעפֿערענס`,
      downloadPDF: () => "?דאָונלאָוד אַ PDF קאָפּיע",
      errorGeneratingPDF: () => "ביטע פּרובירט ווייטער שטונט\nטעעל גענערייטינג PDF",
      pleaseSelectPatient: () => "ביטע אַוואַל פּאַטיענט",
      noMoreThan7Days: () => "איר קענען נאָר ייִבערשטעלן 7 טעג אויף אַ מאָל",
      caregiverCodeRequired: () => "מיטאַרבעטער נומער איז געווענען",
      unableToGeneratePDF: () => "נישט קענען גענערייטינג טיימשיט PDF",
      areYouSureTheDataYouEnteredIsCorrect: () =>
        "?ביסט איר זיכער די דאַטן איר איינגעגעבן איז ריכטיק",
    },
  },
  days: {
    sunday: () => "זונטיק",
    monday: () => "מאָנטיק",
    tuesday: () => "דינסטיק",
    wednesday: () => "מיטוואך",
    thursday: () => "דאָנערשטיק",
    friday: () => "פֿרײַטיק",
    saturday: () => "שבת",
  },
  timesheet: {
    xDutiesAreRequiredInThisSection: (minimum) =>
      `פארן דערפֿן דעם אָפּטיי ${minimum.toString()} דיוטיז אין דעם אָפּשטיי`,
    checkOffDaysPatientCannotSign: () =>
      "Please check off all days when the patient could not sign",
    hhaDutyForm: () => "היים העלץ אַיד דוטי פֿאָרמ",
    patientSignature: () => "פּאַטיענט האָנט",
    hhaSignature: () => "היים העלץ אַיד האָנט",
    signHere: () => "אונטערשרײַבן דאָ",
    patientUnableToSign: () => "פּאַטיענט אָנפֿעל צו האָנט",
    whyPatientCannotSign: () => "ביטע ווייַזן וואָרום דער פּאַטיענט קען נישט האָנט",
    whyWithQuestionMark: () => "?װאָרום",
    noDuplicateDaysOrDates: () => "דופּליקאַטע טעג אָדער דאַטעס זענען נישט אַלאַוד",
    pleaseFillAllFields: () => "ביטע פֿילן אַלע פֿעלדער",
    atLeastOneLine: () => "אויספֿיר עטלעכע קאָן ליניע",
    atLeastOneVisit: () => "ביטע אויסקלייַבן איין באַזוכט צו שטעלן",
    dateAndTime: () => "דאַטע און צײַט",
    timeCannotBeInFuture: () => "צײַט קען נישט זיין אין די צוקונפֿט",
    endingDayOfWeek: (endingDayOfWeek: string) => `${endingDayOfWeek} סוף`,
    date: () => "דאַטע",
    day: () => "טאָג",
    timeArrived: () => "צײַט ארייַפֿגעקומען ביי פּאַטיענט",
    timeLeft: () => "צײַט געלעפֿט ביי פּאַטיענט",
    totalHours: () => "גאַנץ שעות געאַרבעט",
    overnightShiftWithQuestionMark: () => "?יבערנאַכט שיפֿט",
    scheduledTime: (time: string) => `${time} :געשעדולט`,
    duties: () => "דוטיעס",
    checkOffDuties: () => "ביטע טשעק אַוועק אלע דויוטיעס אָפּגעשלאָסן",
    information: () => "אינפֿאָרמאַציע",
    caregiverName: () => "מיטאַרבעטער נאָמען",
    socialSecurityNumber: () => "סאָציאַל זיכערהייַט נומער",
    caregiverCode: () => "מיטאַרבעטער נומער",
    patientName: () => "פּאַטיענט נאָמען",
    phone: () => "טעלעפֿאָן",
    year: () => "יאָר",
    patientAddress: () => "פּאַטיענט אַדרעס",
    patientId: () => "פּאַטיענט דאָסיס",
    timesheetReason: () => "צייט-שיט סיבה",
    reason: () => "סיבה",
    select: () => "אויסקלייַבן",
    missing: {
      hhaSignature: () => "היים העלץ אַיד האָנט איז פֿעהלן",
      patientSignature: () => "פּאַטיענט האָנט איז פֿעהלן",
      patientId: () => "פּאַטיענט דאָסיס איז פֿעהלן",
      patientName: () => "פּאַטיענט נאָמען איז פֿעהלן",
      patientAddress: () => "פּאַטיענט אַדרעס איז פֿעהלן",
      caregiverName: () => "מיטאַרבעטער נאָמען איז פֿעהלן",
      caregiverCode: () => "מיטאַרבעטער נומער איז פֿעהלן",
      pocCompliance: () => "פּפּליקאַציע פּרובירן פֿאַרשריבונג נישט קעגן אָפּ / POC Compliance",
      timesheetReason: () => "טיימסשעט סיבה איז פעלנדיק.",
    },
    signInBoxBelow: () => "ביטע הײַנטרייען אין דער בלעטער טייך",
  },
  errors: {
    caregiverIdRequired: () => "פּלייגער נומער איז נאָטווענדיק",
    firstNameRequired: () => "דער פֿירסט נאָמען איז נאָטווענדיק",
    lastNameRequired: () => "דער לעצטער נאָמען איז נאָטווענדיק",
    ssnRequired: () => "SSN איז נאָטווענדיק",
    dayRequired: () => "טאָג איז נאָטווענדיק",
    dateRequired: () => "דאַטע איז נאָטווענדיק",
    timeCannotBeInTheFuture: () => "צייַט קען נישט זיין אין די צוקונפֿט",
    totalHrsRequired: () => "געזאַמטע שעות גערויכן איז",
    greaterThan0: () => "געזאַמטע שעות מיז זיין גרעסער ווײַן 0",
    lessThan24hrs: () => "געזאַמטע שעות מיז זיין מינדער ווי 24 שעות",
    mustBeInteger: () => "געזאַמטע שעות מוסט זיין אַ גאַנצער צאָל",
    ssnFormat: () => "SSN מוסט זיין אין די פֿאָרמאַט פון 123-45-6789",
    ssnMustBe9Digits: () => "SSN מוסט זיין פֿאַראויס 9 דיגיטן",
    caregiverNameRequired: () => "מיטאַרבייטער נאָמען איז נאָטווענדיק",
    caregiverCodeRequired: () => "מיטאַרבייטער נומער איז נאָטווענדיק",
    patientNameRequired: () => "פּאַציענט נאָמען איז נאָטווענדיק",
    invalidYear: () => "געבן אַ גילטיקער יאָר",
    patientAddressRequired: () => "פּאַציענט אַדרעס איז נאָטווענדיק",
    patientIdRequired: () => "פּאַציענט נומער איז נאָטווענדיק",
    invalidPatientId: () => "געבן אַ גילטיקער פּאַציענט נומער",
    phoneNumberRequired: () => "טעלעפאָן נומער איז נאָטווענדיק",
  },
  mainainance: {
    maintainanceInProgress: () => "אויפהאלטן אין פּראָצעס",
    weAreApplyingUpgradesOperationalSoon: () =>
      "די הייוווע איז ווערן אויסגעמאכט און פּאַרברייט, מיר וועט זיין אַפּעראַטיװ באַלד",
    thankYouForYourPatience: () => "דאַנק דיר פֿאַר דײַן געדולד",
    maintainanceWillBePerformed: (startTime, endTime) =>
      "טייַמהיוווע וועט זיין אין אָפּעראַציאָנאַל פֿאַר געפּלאַנטער מענטענאַנס ביזוי " +
      startTime +
      " און " +
      endTime,
  },
};

export default yiddish;
