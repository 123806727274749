import * as yup from "yup";

export const auth0TokenResponseSchema = yup.object({
  access_token: yup.string().required("Access token is required"),
  expires_in: yup.number().required("Expires in is required"),
  token_type: yup.string().required("Token type is required"),
  scope: yup.string().required("Scope is required"),
});

export type Auth0TokenResponseType = yup.InferType<typeof auth0TokenResponseSchema>;
