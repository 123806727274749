import { type PayloadAction, createSlice } from "@reduxjs/toolkit";
import { type TenantTaskType } from "../../services/schemas/tenant-task.schema";
import { type MissingSignatureReasonType } from "../../services/schemas/missing-signature-reason.schema";
import { type TimeSheetReasonType } from "../../services/schemas/timesheetReason.schema";
import { type POCComplianceType } from "../../services/schemas/poc-compliance.schema";

export const APP_INFO_SLICE_NAME = "app_info";

export interface IAppInfoState {
  tenantId: string;
  logo: string;
  companyName: string;
  companyAddress: string;
  companyPhone: string;
  tasks: TenantTaskType[];
  missingSignatureReasons: MissingSignatureReasonType[];
  tutorialRunning: boolean;
  tutorialIndex: number;
  timesheetReasons: TimeSheetReasonType[];
  pocCompliance: POCComplianceType;
  patientSignatureRequired: boolean;
  timesheetTemplate: string;
  timesheetReasonRequired: boolean;
}

const initialPocCompliance: POCComplianceType = {
  id: "",
  insertedAt: "",
  insertedBy: "",
  minimumTasks: 0,
  pocComplianceRules: [],
  tenantId: "",
  updatedAt: "",
};

const initialState: IAppInfoState = {
  tenantId: "",
  logo: "",
  companyName: "",
  companyAddress: "",
  companyPhone: "",
  tasks: [],
  missingSignatureReasons: [],
  tutorialRunning: false,
  tutorialIndex: 0,
  timesheetReasons: [],
  pocCompliance: initialPocCompliance,
  patientSignatureRequired: false,
  timesheetTemplate: "",
  timesheetReasonRequired: false,
};

const appInfoSlice = createSlice({
  name: APP_INFO_SLICE_NAME,
  initialState,
  reducers: {
    setTenantId: (state, action: PayloadAction<IAppInfoState["tenantId"]>) => {
      state.tenantId = action.payload;
    },
    setPartialAppInfo: (state, action: PayloadAction<Partial<IAppInfoState>>) => {
      return { ...state, ...action.payload };
    },
    toggleTutorialRunning: (state) => {
      state.tutorialRunning = !state.tutorialRunning;
    },
    setTutorialIndex: (state, action: PayloadAction<IAppInfoState["tutorialIndex"]>) => {
      state.tutorialIndex = action.payload;
    },
    resetAppInfo: () => {
      return initialState;
    },
  },
});

export const {
  setTenantId: setTenantIdAction,
  setPartialAppInfo: setPartialAppInfoAction,
  resetAppInfo: resetAppInfoAction,
  toggleTutorialRunning: toggleTutorialRunningAction,
  setTutorialIndex: setTutorialIndexAction,
} = appInfoSlice.actions;

export const appInfoReducer = appInfoSlice.reducer;
