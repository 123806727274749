import { createContext, useMemo, useState } from "react";
import type translations from "../helpers/translations";

export interface ILanguageContext {
  language: TLanguage;
  setLanguage: (newLanguage: TLanguage) => void;
}

interface ILanguageProviderProps {
  children: React.ReactNode;
}

export type TLanguage = keyof typeof translations;

export const LanguageContext = createContext<ILanguageContext | null>(null);

const LanguageContextProvider = ({ children }: ILanguageProviderProps): JSX.Element => {
  const [language, setLanguage] = useState<TLanguage>("eng");

  const contextValue = useMemo(() => {
    return {
      language,
      setLanguage,
    };
  }, [language]);
  return <LanguageContext.Provider value={contextValue}>{children}</LanguageContext.Provider>;
};

export default LanguageContextProvider;
