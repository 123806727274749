import { useContext, type ChangeEvent } from "react";
import useTranslations from "../helpers/scoped-translation";
import translations from "../helpers/translations";
import { LanguageContext, type TLanguage } from "../context/LanguageContext";
import { useAuth0 } from "@auth0/auth0-react";
import moment from "moment";
import { useCookies } from "react-cookie";
import toast from "react-hot-toast";
import { useUpdateUserAuth0Mutation } from "../services/mutations/user.auth0.mutations";
import { preferredLanguageCookieName } from "../helpers/constant/cookies";
import { useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "@tanstack/react-location";

const LanguageSelector = (): JSX.Element => {
  const { user, isAuthenticated } = useAuth0();
  const languageContext = useContext(LanguageContext);
  const { mutate: updateUserAuth0 } = useUpdateUserAuth0Mutation();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const t = useTranslations();
  const [, setCookie] = useCookies();

  const handleOnSuccess = (newLanguageSelection: TLanguage) => {
    languageContext?.setLanguage(newLanguageSelection);
    moment.locale(translations[newLanguageSelection].twoCharCode());
    setCookie(preferredLanguageCookieName, newLanguageSelection);
    if (isAuthenticated && user?.sub) {
      queryClient.removeQueries(["auth0User"]);
      location.pathname !== "/visits" && navigate({ to: "visits" });
    }
  };

  const handleLanguageSelection = (e: ChangeEvent<HTMLSelectElement>) => {
    const newLanguageSelection = e.target.value as TLanguage;
    if (location.pathname === "/" && (!isAuthenticated || !user?.sub)) {
      handleOnSuccess(newLanguageSelection);
    } else {
      if (!isAuthenticated || !user?.sub) {
        toast.error(t.dialogs.resetSSN.userIDNotFound());
        return;
      }
      updateUserAuth0(
        {
          userId: user?.sub ?? "",
          user_metadata: { preferredLanguage: newLanguageSelection },
        },
        {
          onSuccess: () => handleOnSuccess,
          onError: () => toast.error(t.sorryTryAgain()),
        },
      );
    }
  };

  return (
    <>
      <label className={!isAuthenticated ? "text-xs" : ""}>{t.language()}:</label>
      <select
        value={languageContext?.language}
        onChange={handleLanguageSelection}
        className={!isAuthenticated ? "text-xs" : ""}
      >
        {(Object.keys(t.languages) as Array<keyof typeof t.languages>).map((lKey) => (
          <option value={lKey} key={"language-option-" + lKey}>
            ({translations[lKey].twoCharCode()}) {t.languages[lKey]()}
          </option>
        ))}
      </select>
    </>
  );
};

export default LanguageSelector;
