import { ReactLocation } from "@tanstack/react-location";
import { useEffect } from "react";

interface IAuthCheckpointProviderProps {
  children: React.ReactNode;
}

const location = new ReactLocation();

const URLFormatCheckpoint = ({ children }: IAuthCheckpointProviderProps) => {
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const urlTenantIdValue = params.get("tenantId");
    if (urlTenantIdValue) window.location.replace("/" + urlTenantIdValue);
  }, [location.current.search]);

  return children;
};

export default URLFormatCheckpoint;
