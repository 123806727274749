import * as yup from "yup";

export const auth0UserSchema = yup.object({
  user_id: yup.string().required(),
  email: yup.string().email().required(),
  email_verified: yup.boolean().required(),
  username: yup.string().optional(),
  phone_number: yup.string().optional(),
  phone_verified: yup.boolean().optional(),
  created_at: yup.string().required(),
  updated_at: yup.string().required(),
  identities: yup
    .array()
    .of(
      yup.object({
        connection: yup.string().required(),
        user_id: yup.string().required(),
        provider: yup.string().required(),
        isSocial: yup.boolean().required(),
        access_token: yup.string().optional(),
        access_token_secret: yup.string().optional(),
        refresh_token: yup.string().optional(),
        profileData: yup
          .object({
            email: yup.string().email().required(),
            email_verified: yup.boolean().required(),
            name: yup.string().required(),
            given_name: yup.string().required(),
            family_name: yup.string().required(),
          })
          .optional(),
      }),
    )
    .optional(),
  app_metadata: yup.object().optional(),
  user_metadata: yup
    .object({
      // ssn: yup.string().required().nullable(),
      timeHiveTenants: yup.array().of(
        yup.object({
          tenantId: yup.string().required(),
          caregiverIds: yup.array().of(yup.object({ caregiverId: yup.string().required() })),
        }),
      ),
      preferredLanguage: yup.string().optional(),
    })
    .required(),
  picture: yup.string().optional(),
  name: yup.string().required(),
  nickname: yup.string().optional(),
  multifactor: yup.array().of(yup.string()).optional(),
  last_ip: yup.string().optional(),
  last_login: yup.string().optional(),
  logins_count: yup.number().optional(),
  blocked: yup.boolean().optional(),
  given_name: yup.string().optional(),
  family_name: yup.string().optional(),
});

export type Auth0UserType = yup.InferType<typeof auth0UserSchema>;
