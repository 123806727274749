import * as yup from "yup";

export const maintenanceSchema = yup.object({
  id: yup.number().required(),
  maintenanceActive: yup.boolean().required(),
  activeMaintenanceMessage: yup.string().nullable(),
  globalWarning: yup.string().nullable(),
  globalInformation: yup.string().nullable(),
});

export type MaintenanceType = yup.InferType<typeof maintenanceSchema>;
