import useTranslations from "../helpers/scoped-translation";
import { useMaintenance } from "../services/queries/maintenance.query";

const MaintenancePage = () => {
  const t = useTranslations();
  const { data } = useMaintenance();
  return (
    <div className='container mx-auto flex flex-col justify-center items-center h-screen text-center'>
      <h1 className='text-5xl font-bold text-error'>{t.mainainance.maintainanceInProgress()}</h1>
      <br />
      <p className='text-2xl text-primary'>
        {data?.activeMaintenanceMessage ?? t.mainainance.weAreApplyingUpgradesOperationalSoon()}
      </p>
      <br />
      <h2 className='card-title'>{t.mainainance.thankYouForYourPatience()}</h2>
    </div>
  );
};

export default MaintenancePage;
