import { useMutation, useQueryClient } from "@tanstack/react-query";
import { type IUpdateUserArgs, updateUser } from "../requests/user.auth0.request";

export const useUpdateUserAuth0Mutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (data: IUpdateUserArgs) => {
      await updateUser(data);
    },
    onError: () => {},
    onSuccess: async () => {
      void queryClient.invalidateQueries(["auth0User"]);
      // void queryClient.invalidateQueries(["visits"]);
    },
  });
};
