import { useEffect, useState } from "react";
import { useAppSelector } from "../../redux/store";
import useTranslations from "../../helpers/scoped-translation";
import { useNavigate } from "@tanstack/react-location";

const NoTenantDialog = (): JSX.Element => {
  const { tenantId } = useAppSelector((state) => state.app_info);
  const t = useTranslations();
  const navigate = useNavigate();
  const [tenantIdValue, setTenantIdValue] = useState("");

  useEffect(() => {
    !!tenantId && window.no_tenant_modal?.close();
  }, [tenantId]);

  const onSet = () => {
    navigate({ to: `${tenantIdValue}` });
  };

  return (
    <dialog id='no_tenant_modal' className='modal'>
      <div className='modal-box'>
        <form method='dialog' onSubmit={onSet}>
          <h3 className='font-bold text-lg'>{t.dialogs.noTenant.warningNoTenantFound()}</h3>
          <p className='py-4'>{t.dialogs.noTenant.details()}</p>
          <input
            className='input input-bordered w-full'
            type='text'
            value={tenantIdValue}
            placeholder={t.dialogs.noTenant.enterIdentifier()}
            onChange={(e) => {
              setTenantIdValue(e.target.value);
            }}
          />
          <div className='modal-action'>
            <button className='btn btn-primary' type='submit'>
              {t.actions.submit()}
            </button>
          </div>
        </form>
      </div>
    </dialog>
  );
};

export default NoTenantDialog;
