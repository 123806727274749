import { type DefaultGenerics, type LoaderFn } from "@tanstack/react-location";
import store from "../redux/store";
import { setPartialAppInfoAction } from "../redux/features/app-info.slice";
import { getTenant } from "../services/requests/tenant.request";
import toast from "react-hot-toast";
import { AxiosError } from "axios";

export const tenantLoader: LoaderFn<DefaultGenerics> = async ({ params }): Promise<object> => {
  try {
    const tenant = await getTenant(params.tenantId);
    if (!tenant || tenant === undefined) {
      toast.error("Tenant not found", { id: "tenant" });
      return {};
    }
    store.dispatch(
      setPartialAppInfoAction({
        tenantId: params.tenantId,
        companyAddress: tenant.address,
        companyName: tenant.name,
        logo: tenant.logo,
        companyPhone: tenant.phoneNumber,
        tasks: tenant.tasks,
        missingSignatureReasons: tenant.missingSignatureReasons,
        timesheetReasons: tenant.timesheetReasons,
        pocCompliance: tenant.pocCompliance,
        patientSignatureRequired: tenant.patientSignatureRequired,
        timesheetTemplate: tenant.timesheetTemplate,
        timesheetReasonRequired: tenant.timesheetReasonRequired,
      }),
    );

    return {};
  } catch (error) {
    if (error instanceof AxiosError) {
      const errorMessage =
        error.response?.status === 404 ? "Tenant not found" : error.response?.data?.message;
      toast.error(errorMessage, { id: "tenant" });
    }
    return {};
  }
};
