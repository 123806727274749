import Axios from "axios";

const apiManagement = Axios.create({
  baseURL: "https://" + window._env_.REACT_APP_AUTH0_DOMAIN + "/api/v2/",
});

apiManagement.interceptors.request.use(
  (config) => {
    return config;
  },
  async (error) => {
    return await Promise.reject(error);
  },
);

apiManagement.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    if ([401, 403].includes(error.response?.status)) {
      // window.unauthorize_modal.showModal();
    }
    return await Promise.reject(error);
  },
);

export const setAuthorizationHeader = (token: string): void => {
  const trimmedToken = token.trim();
  if (!trimmedToken) throw new Error("Token is empty");
  apiManagement.defaults.headers.common.Authorization = `Bearer ${trimmedToken}`;
};

export default apiManagement;
