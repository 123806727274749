import apiTimeHive from "../../configs/api-timehive.config";
import { type TenantType } from "../schemas/tenant.schema";

interface TenantResponseType {
  data: TenantType;
  message: string;
  error: boolean;
}

export const getTenant = async (tenantId: string): Promise<TenantType> => {
  const response = await apiTimeHive.get<TenantResponseType>(`/Config/Tenant/${tenantId}/Safe`);
  return response.data.data;
};
