import { type ITranslation } from "../../types/types";

const mandarin: ITranslation = {
  tutorial: {
    stepOne: {
      newUsers: () => "新用户",
      existingUsers: () => "现有用户",
      clickOnGetStarted: () => "点击“开始”将引导您到我们的身份验证服务Auth0。",
      newUserNextSteps: () => `注册后，Auth0将重定向您回到TimeHive。`,
      existingUserNextSteps: () =>
        `登录后，Auth0将重定向您回到TimeHive。如果您已经登录，则会立即重定向回TimeHive。`,
    },
    clickIcon: () => "点击您的图标以打开下拉菜单。",
    useButtons: () => "使用这些按钮来帮助您选择要确认的访问。",
    clickToSelectVisits: () => "单击选择您希望确认的访问。",
    proceedConfirm: () => "继续确认所选访问。",
    onlyRedAsterixRequired: () => "没有红色星号的字段不是必需的，可能为空。",
    verifyYourInfo: () => "验证您的信息是否正确。如需更改，请联系管理员。",
    verifyPatientInfo: () => "验证患者信息是否正确。如需更改，请联系管理员。",
    verifyPatientAddressAndId: () => "验证或输入患者的地址和ID。",
    verifyDateTimes: () => "验证访问的日期和时间。",
    checkDuties: () =>
      "为您确认的每一天（访问）勾选您执行的职责。根据您的机构，这可能可见也可能不可见。",
    mayNotBeVisible: () => "根据您的机构，这可能可见也可能不可见。",
    provideSignatures: () => "为患者和您自己提供签名。",
    checkDaysUnableToSign: () => "勾选并解释患者无法签名的任何天的原因。",
    clickSubmitData: () => "单击提交您输入的数据并确认您的访问。",
    downloadPDF: () => "保持选中以下载您时间表的PDF副本。",
    finalSubmit: () => "无法回退！单击此处发送您的数据并确认您的访问。",
  },
  twoCharCode: () => "zh",
  language: () => "语言",
  languages: {
    eng: () => "英语",
    spa: () => "西班牙语",
    rus: () => "俄语",
    kor: () => "韩语",
    yue: () => "粤语",
    cmn: () => "普通话",
    ben: () => "孟加拉语",
    ceb: () => "宿务话",
    yid: () => "意第绪语",
  },
  navigation: {
    home: () => "首页",
    visits: () => "访问",
  },
  dropdown: {
    aboutToLeave: () => "等等，您即将离开！",
    confirmLogout: () => "您确定要注销吗？",
  },
  actions: {
    ok: () => "好的",
    cancel: () => "取消",
    clear: () => "清除",
    close: () => "关闭",
    remove: () => "移除",
    submit: () => "提交",
    save: () => "保存",
    savingTrailOff: () => "保存中...",
    update: () => "更新",
    updatingTrailOff: () => "更新中...",
    confirm: () => "确认",
    submittingTrailOff: () => "提交中...",
    sync: () => "同步",
    syncTrailOff: () => "同步中...",
    change: () => "更改",
    back: () => "返回",
    done: () => "完成",
    next: () => "旁边",
  },
  welcomeToTimeHive: () => "欢迎来到TimeHive",
  welcomeToTimeHivePleaseUpdateInfo: () => "欢迎来到TimeHive，请更新下面的信息以继续",
  completeYourVisits: () => "完成您的访问",
  getStarted: () => "开始",
  noteExclamation: () => "注意！",
  noSensitiveDataSaved: () =>
    "我们不保存个人可识别信息。此输入将用于在我们的EHR系统中识别您的个人资料。",
  resetSSN: () => "重置SSN",
  switchProfile: () => "更改机构",
  selectProfile: () => "选择机构",
  noProfiles: () => "无机构",
  noIncompleteVisits: () => "无未完成的访问",
  login: () => "登录",
  loginToContinue: () => "立即登录以继续。",
  reload: () => "重新加载",
  logout: () => "注销",
  youAreLoggedOut: () => "您已注销！",
  loginAgainToUse: () => "重新登录以使用时间表",
  caregiverId: (caregiverId: string) => `照顾者ID：${caregiverId}`,
  "timesheets/days": (number?: number) => `${number ?? ""}个时间表/天`,
  redirecting: () => "正在重定向",
  redirectingWithExclamation: () => "正在重定向！",
  sortByDay: () => "按天排序",
  selectByWeek: () => "按周选择",
  updateSelectedVisits: (numberOfVisits: number) =>
    `更新${numberOfVisits}个已选择的访问${numberOfVisits > 1 ? "s" : ""}`,
  fetchingVisits: () => "正在获取访问",
  myIncompleteVisits: () => "我的未完成访问",
  patientWithName: (patientName: string) => `患者：${patientName}`,
  visitDateWithDate: (visitDate: string) => `访问日期：${visitDate}`,
  timeVisitStarted: (time: string) => `访问开始时间：${time}`,
  timeVisitEnded: (time: string) => `访问结束时间：${time}`,
  notProvided: () => "未提供",
  totalAccumulatedTimeWithColon: () => `累计时间：`,
  hoursShort: () => "小时",
  minutesShort: () => "分钟",
  theHiveAwaits: () => "蜂巢在等待",
  noTenantPleaseCheckLink: () => "找不到租户。请与您的IT部门验证链接。",
  waitToLoadTenant: () => "请稍候，我们加载您的租户...",
  noSignatureFound: () => "未找到签名",
  typeHere: () => "在此处输入",
  sorryTryAgain: () => "对不起，再试一次",
  dialogs: {
    escapeOrClickButtonToClose: () => "按ESC键或单击下面的按钮关闭",
    unauthorized: {
      warningUnauthorized: () => "警告：未经授权的访问！",
      details: () => "您未被授权访问此页面。请联系您的IT获取支持。",
    },
    noTenant: {
      warningNoTenantFound: () => "警告：在URL中找不到租户！",
      details: () => "在继续之前，必须指定租户。请与您的IT团队验证链接。",
      enterIdentifier: () => "输入租户的身份证",
    },
    resetSSN: {
      ssn: () => "社会安全号",
      userIDNotFound: () => "找不到用户ID",
    },
    submitTimesheetOptions: {
      instructionsToSubmit: {
        part1: () => "当您点击",
        part2: () => "确认",
        part3: () => "时，您的时间表将被发布",
      },
      somethingWentWrong: () => "哎呀！提交过程中出了些问题。",
      errorReference: (errorCode: string) => `参考编号：${errorCode}`,
      downloadPDF: () => "下载PDF副本？",
      errorGeneratingPDF: () => "生成PDF时出错。\n请稍后再试。",
      pleaseSelectPatient: () => "请选择一个患者",
      noMoreThan7Days: () => "您一次只能提交7天",
      caregiverCodeRequired: () => "需要员工号码",
      unableToGeneratePDF: () => "无法生成时间表PDF",
      areYouSureTheDataYouEnteredIsCorrect: () => "您确定输入的数据是正确的吗？",
    },
  },
  days: {
    sunday: () => "星期日",
    monday: () => "星期一",
    tuesday: () => "星期二",
    wednesday: () => "星期三",
    thursday: () => "星期四",
    friday: () => "星期五",
    saturday: () => "星期六",
  },
  timesheet: {
    xDutiesAreRequiredInThisSection: (minimum) => `此部分至少需要 ${minimum.toString()} 项职责`,
    checkOffDaysPatientCannotSign: () =>
      "Please check off all days when the patient could not sign",
    hhaDutyForm: () => "家庭护理助手职责表",
    patientSignature: () => "患者签名",
    hhaSignature: () => "家庭护理助手签名",
    signHere: () => "在这里签名",
    patientUnableToSign: () => "患者无法签名",
    whyPatientCannotSign: () => "请说明患者无法签名的原因",
    whyWithQuestionMark: () => "为什么？",
    noDuplicateDaysOrDates: () => "不允许重复的天数或日期",
    pleaseFillAllFields: () => "请填写所有字段",
    atLeastOneLine: () => "至少需要一行",
    atLeastOneVisit: () => "请至少选择一个访问进行提交。",
    dateAndTime: () => "日期和时间",
    timeCannotBeInFuture: () => "时间不能是未来的",
    endingDayOfWeek: (endingDayOfWeek: string) => `结束于${endingDayOfWeek}`,
    date: () => "日期",
    day: () => "天",
    timeArrived: () => "到达患者时间",
    timeLeft: () => "离开患者时间",
    totalHours: () => "总工作时间",
    overnightShiftWithQuestionMark: () => "过夜班？",
    scheduledTime: (time: string) => `计划时间：${time}`,
    duties: () => "职责",
    checkOffDuties: () => "请勾选所有已完成的职责",
    information: () => "信息",
    caregiverName: () => "员工姓名",
    socialSecurityNumber: () => "社会安全号码",
    caregiverCode: () => "员工号码",
    patientName: () => "患者姓名",
    phone: () => "电话",
    year: () => "年",
    patientAddress: () => "患者地址",
    patientId: () => "患者ID",
    timesheetReason: () => "时间表原因",
    reason: () => "原因",
    select: () => "选择",
    missing: {
      hhaSignature: () => "缺少家庭护理助手签名。",
      patientSignature: () => "缺少患者签名。",
      patientId: () => "缺少患者ID。",
      patientName: () => "缺少患者姓名。",
      patientAddress: () => "缺少患者地址。",
      caregiverName: () => "缺少员工姓名。",
      caregiverCode: () => "缺少员工号码。",
      pocCompliance: () => "必要职责未勾选 / POC Compliance",
      timesheetReason: () => "缺少时间表原因。",
    },
    signInBoxBelow: () => "请在下方签名",
  },
  errors: {
    caregiverIdRequired: () => "护理员编号必填",
    firstNameRequired: () => "名字必填",
    lastNameRequired: () => "姓氏必填",
    ssnRequired: () => "社会安全号码必填",
    dayRequired: () => "日期必填",
    dateRequired: () => "日期必填",
    timeCannotBeInTheFuture: () => "时间不能在未来",
    totalHrsRequired: () => "总工时必填",
    greaterThan0: () => "总工时必须大于0",
    lessThan24hrs: () => "总工时必须小于24小时",
    mustBeInteger: () => "总工时必须为整数",
    ssnFormat: () => "社会安全号码必须为123-45-6789格式",
    ssnMustBe9Digits: () => "社会安全号码必须为9位数字",
    caregiverNameRequired: () => "员工姓名必填",
    caregiverCodeRequired: () => "员工编号必填",
    patientNameRequired: () => "病人姓名必填",
    invalidYear: () => "请输入有效年份",
    patientAddressRequired: () => "病人地址必填",
    patientIdRequired: () => "病人编号必填",
    invalidPatientId: () => "请输入有效病人编号",
    phoneNumberRequired: () => "电话号码必填",
  },
  mainainance: {
    maintainanceInProgress: () => "维护进行中",
    weAreApplyingUpgradesOperationalSoon: () => "蜂巢正在清理和扩展，我们将很快恢复运营",
    thankYouForYourPatience: () => "感谢您的耐心",
    maintainanceWillBePerformed: (startTime, endTime) =>
      "TimeHive 将在 " + startTime + " 到 " + endTime + " 之间进行计划维护",
  },
};

export default mandarin;
