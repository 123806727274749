import apiTimeHive from "../../configs/api-timehive.config";
import { type MaintenanceType } from "../schemas/maintenance.schema";

interface MaintenanceResponseType {
  data: MaintenanceType;
  message: string;
  error: boolean;
}

export const getMaintenance = async (): Promise<MaintenanceType> => {
  const { data } = await apiTimeHive.get<MaintenanceResponseType>(`/Config/Maintenance`);
  return data.data;
};
