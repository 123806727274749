import auth0Authorization from "../../configs/auth0Authorization.config";
import { type Auth0TokenResponseType, auth0TokenResponseSchema } from "../schemas/token.schema";

export const getAccessToken = async (): Promise<Auth0TokenResponseType> => {
  const { data } = await auth0Authorization.post(
    "/token",
    {
      client_id: window._env_.REACT_APP_AUTH0_API_EXPLORER_CLIENT_ID,
      client_secret: window._env_.REACT_APP_AUTH0_API_EXPLORER_CLIENT_SECRET,
      audience: window._env_.REACT_APP_AUTH0_MANAGEMENT_API_AUDIENCE,
      grant_type: "client_credentials",
    },
    {
      headers: {
        "Content-Type": "application/json",
      },
    },
  );
  return await auth0TokenResponseSchema.validate(data, {
    abortEarly: true,
    strict: true,
    stripUnknown: true,
  });
};
