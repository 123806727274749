import Axios from "axios";
import store from "../redux/store";
const apiTimeHiveHHA = Axios.create({
  baseURL: window._env_.REACT_APP_TIMEHIVE_API_BASEURL,
});

apiTimeHiveHHA.interceptors.request.use(
  async (config) => {
    config.headers.set("Tenant-Id", store.getState().app_info.tenantId);
    return config;
  },
  async (error) => {
    return await Promise.reject(error);
  },
);

apiTimeHiveHHA.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    if ([401, 403].includes(error.response.status)) {
      // window.unauthorize_modal.showModal();
    }
    return await Promise.reject(error);
  },
);

export const setAuthorizationHeader = (token: string): void => {
  const trimmedToken = token.trim();
  if (!trimmedToken) throw new Error("Token is empty");
  apiTimeHiveHHA.defaults.headers.common.Authorization = `Bearer ${trimmedToken}`;
};

export default apiTimeHiveHHA;
