import { createContext, useState, useMemo } from "react";

export interface IAuthCheckpointContext {
  auth0Management: {
    isAuthenticated: boolean;
    accessToken: string;
  };
  setAuth0Management: (auth0Management: { isAuthenticated: boolean; accessToken: string }) => void;
  auth0TimeHive: {
    isAuthenticated: boolean;
    accessToken: string;
  };
  setAuth0TimeHive: (auth0TimeHive: { isAuthenticated: boolean; accessToken: string }) => void;
  auth0TimeHiveConfig: {
    isAuthenticated: boolean;
    accessToken: string;
  };
  setAuth0TimeHiveConfig: (auth0TimeHiveConfig: {
    isAuthenticated: boolean;
    accessToken: string;
  }) => void;
}

export const AuthCheckpointContext = createContext<IAuthCheckpointContext | null>(null);

interface IAuthCheckpointProviderProps {
  children: React.ReactNode;
}

export default ({ children }: IAuthCheckpointProviderProps): JSX.Element => {
  const [auth0Management, setAuth0Management] = useState({
    accessToken: "",
    isAuthenticated: false,
  });
  const [auth0TimeHive, setAuth0TimeHive] = useState({
    accessToken: "",
    isAuthenticated: false,
  });
  const [auth0TimeHiveConfig, setAuth0TimeHiveConfig] = useState({
    accessToken: "",
    isAuthenticated: false,
  });

  const contextValue = useMemo(() => {
    return {
      auth0Management,
      setAuth0Management,
      auth0TimeHive,
      setAuth0TimeHive,
      auth0TimeHiveConfig,
      setAuth0TimeHiveConfig,
    };
  }, [auth0Management, auth0TimeHive, auth0TimeHiveConfig]);
  return (
    <AuthCheckpointContext.Provider value={contextValue}>{children}</AuthCheckpointContext.Provider>
  );
};
