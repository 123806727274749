import { createSlice, type PayloadAction } from "@reduxjs/toolkit";
import type {
  CaregiverProfilesType,
  CaregiverProfileType,
} from "../../services/schemas/caregiver-profile.schema";

export const CAREGIVER_INFO_SLICE_NAME = "caregiver_info";

export interface ICaregiverInfoState {
  caregiverProfiles: CaregiverProfilesType;
  defaultProfile?: CaregiverProfileType;
}

const initialFormState: ICaregiverInfoState = {
  caregiverProfiles: [],
  defaultProfile: undefined,
};

export const caregiverInfoSlice = createSlice({
  name: CAREGIVER_INFO_SLICE_NAME,
  initialState: initialFormState,
  reducers: {
    setPartialCaregiverInfo: (state, action: PayloadAction<Partial<ICaregiverInfoState>>) => {
      state = { ...state, ...action.payload };
      return state;
    },
    clearCaregiverInfo: (state) => {
      state = initialFormState;
      return state;
    },
  },
});

export const caregiverInfoReducer = caregiverInfoSlice.reducer;

export const {
  setPartialCaregiverInfo: setPartialCaregiverInfoAction,
  clearCaregiverInfo: clearCaregiverInfoAction,
} = caregiverInfoSlice.actions;
