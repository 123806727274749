import { useQueryClient } from "@tanstack/react-query";
import { useMaintenance } from "../services/queries/maintenance.query";

export default function GlobalAlerts(): JSX.Element {
  const { data: maintenance } = useMaintenance();
  const queryClient = useQueryClient();
  return (
    <div className='container mx-auto flex flex-col gap-2 mt-2'>
      {maintenance?.globalInformation ? (
        <div role='alert' className='alert'>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            fill='none'
            viewBox='0 0 24 24'
            className='stroke-info shrink-0 w-6 h-6'
          >
            <path
              strokeLinecap='round'
              strokeLinejoin='round'
              strokeWidth='2'
              d='M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z'
            ></path>
          </svg>
          <span>{maintenance?.globalInformation}</span>
          <button
            className='btn btn-sm'
            onClick={() => {
              queryClient.setQueryData(["maintenance"], {
                ...maintenance,
                globalInformation: "",
              });
            }}
          >
            Dismiss
          </button>
        </div>
      ) : null}
      {maintenance?.globalWarning ? (
        <div role='alert' className='alert'>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            className='stroke-warning shrink-0 h-6 w-6'
            fill='none'
            viewBox='0 0 24 24'
          >
            <path
              strokeLinecap='round'
              strokeLinejoin='round'
              strokeWidth='2'
              d='M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z'
            />
          </svg>
          <span>{maintenance.globalWarning}</span>
          <button
            className='btn btn-sm'
            onClick={() => {
              queryClient.setQueryData(["maintenance"], {
                ...maintenance,
                globalWarning: "",
              });
            }}
          >
            Dismiss
          </button>
        </div>
      ) : null}
    </div>
  );
}
