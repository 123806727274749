import Joyride, { type TooltipRenderProps } from "react-joyride";
import { useAppDispatch, useAppSelector } from "../../redux/store";

import {
  setPartialAppInfoAction,
  setTutorialIndexAction,
} from "../../redux/features/app-info.slice";
import steps from "./TutorialSteps";
import useTranslations from "../../helpers/scoped-translation";
import { useCookies } from "react-cookie";
import { tutorialCookieName } from "../../helpers/constant/cookies";

const CustomJoyrideWrapper = () => {
  const { tutorialRunning, tutorialIndex } = useAppSelector((state) => state.app_info);
  const dispatch = useAppDispatch();
  const t = useTranslations();
  const [, setCookie] = useCookies();

  const closeAndReset = () => {
    dispatch(setPartialAppInfoAction({ tutorialIndex: 0, tutorialRunning: false }));
    setCookie(tutorialCookieName, false);
  };

  const CustomToolip = ({ step }: TooltipRenderProps) => (
    <div
      key='JoyrideTooltip'
      aria-label={"Tutorial step"}
      className='react-joyride__tooltip'
      style={step.styles.tooltip}
    >
      <button
        data-test-id='button-secondary'
        style={step.styles.buttonClose}
        className='mr-2'
        onClick={closeAndReset}
      >
        X
      </button>
      <div style={step.styles.tooltipContainer}>
        {step.title && (
          <h1 aria-label={"getText(step.title)"} style={step.styles.tooltipTitle}>
            {step.title}
          </h1>
        )}
        <div style={step.styles.tooltipContent}>{step.content}</div>
      </div>

      <div style={step.styles.tooltipFooter}>
        {![0, 1, 5, 13].includes(tutorialIndex) && (
          <button
            style={step.styles.buttonBack}
            onClick={() => dispatch(setTutorialIndexAction(tutorialIndex - 1))}
          >
            {t.actions.back()}
          </button>
        )}
        {![0, 4, 12, 14].includes(tutorialIndex) && (
          <button
            style={step.styles.buttonNext}
            onClick={() => dispatch(setTutorialIndexAction(tutorialIndex + 1))}
          >
            {t.actions.next()}
          </button>
        )}
      </div>
    </div>
  );

  return (
    <Joyride
      run={tutorialRunning}
      stepIndex={tutorialIndex}
      steps={steps(t)}
      continuous
      spotlightClicks
      tooltipComponent={CustomToolip}
      scrollToFirstStep
      disableCloseOnEsc
      disableOverlayClose
      locale={{ back: t.actions.back(), close: t.actions.close(), next: t.actions.next() }}
    />
  );
};

export default CustomJoyrideWrapper;
