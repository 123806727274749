import { createSlice, type PayloadAction } from "@reduxjs/toolkit";

export const INFORMATION_SLICE_NAME = "information";

export interface IInformation {
  caregiverName: string;
  ssn?: string;
  caregiverId?: string;
  caregiverCode?: string;
  patientName: string;
  phone?: string;
  patientAddress: string;
  patientId?: string;
  patientUnableToSign?: boolean;
  patientUnableToSignReason?: string;
  timesheetReason?: string;
}

const initialFormState: IInformation = {
  caregiverName: "",
  ssn: "",
  caregiverId: "",
  caregiverCode: "",
  patientName: "",
  phone: "",
  patientAddress: "",
  patientId: "",
  patientUnableToSign: false,
  patientUnableToSignReason: "",
  timesheetReason: "",
};

export const informationSlice = createSlice({
  name: INFORMATION_SLICE_NAME,
  initialState: initialFormState,
  reducers: {
    setInformation: (state, action: PayloadAction<IInformation>) => {
      state = action.payload;
      return state;
    },
    clearInformation: (state) => {
      state = initialFormState;
      return state;
    },
    setPartialInformation: (state, action: PayloadAction<Partial<IInformation>>) => {
      state = { ...state, ...action.payload };
      return state;
    },
  },
});

export default informationSlice.reducer;

export const { setInformation, setPartialInformation, clearInformation } = informationSlice.actions;
