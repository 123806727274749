import { type UseQueryResult, useQuery } from "@tanstack/react-query";
import { useCallback } from "react";
import { AxiosError } from "axios";
import toast from "react-hot-toast";
import { maintenanceSchema, type MaintenanceType } from "../schemas/maintenance.schema";
import { getMaintenance } from "../requests/maintenance.request";

export const useMaintenance = (): UseQueryResult<MaintenanceType> => {
  return useQuery({
    enabled: false,
    queryKey: ["maintenance"],
    queryFn: async () => await getMaintenance(),
    select: useCallback((data: unknown) => {
      return maintenanceSchema.validateSync(data, {
        abortEarly: true,
        stripUnknown: true,
        strict: true,
      });
    }, []),
    useErrorBoundary: useCallback((error: any) => {
      let errorMsg = "Something went wrong while fetching maintenance info";
      if (error instanceof AxiosError) {
        errorMsg =
          error.response?.status === 404 ? "Maintenance not found" : error.response?.data.message;
      }
      toast.error(errorMsg, { id: "Maintenance" });
      return false;
    }, []),
  });
};
