import { type ITranslationObject } from "../types/types";
import bengali from "./translation-languages/ben";
import cebuano from "./translation-languages/ceb";
import mandarin from "./translation-languages/cmn";
import english from "./translation-languages/eng";
import korean from "./translation-languages/kor";
import russian from "./translation-languages/rus";
import spanish from "./translation-languages/spa";
import yiddish from "./translation-languages/yid";
import cantonese from "./translation-languages/yue";

const translations: ITranslationObject = {
  rus: russian,
  spa: spanish,
  kor: korean,
  eng: english,
  yue: cantonese,
  cmn: mandarin,
  ben: bengali,
  yid: yiddish,
  ceb: cebuano,
};

export default translations;
