import type { Route, DefaultGenerics } from "@tanstack/react-location";
import React from "react";
import { tenantLoader } from "../loader/tenant";
const TenantRootPage = React.lazy(async () => await import("../pages/TenantRootPage"));
const LoginPage = React.lazy(async () => await import("../pages/LoginPage"));
const LogoutPage = React.lazy(async () => await import("../pages/LogoutPage"));
const VisitsPage = React.lazy(async () => await import("../pages/VisitsPage"));
const LandingPage = React.lazy(async () => await import("../pages/LandingPage"));
const UpdateIncompleteVisit = React.lazy(
  async () => await import("../pages/UpdateIncompleteVisit"),
);

const SuspenseFallback = () => {
  return (
    <div className='flex justify-center items-center h-screen'>
      <div className='loading loading-spinner'></div>
      Loading Page...
    </div>
  );
};

const SuspenseWrapper = ({ children }: { children: React.ReactNode }) => {
  return <React.Suspense fallback={<SuspenseFallback />}>{children}</React.Suspense>;
};

export const routes: Array<Route<DefaultGenerics>> = [
  {
    path: "/",
    element: (
      <SuspenseWrapper>
        <LandingPage />
      </SuspenseWrapper>
    ),
  },
  {
    path: "/login",
    element: (
      <SuspenseWrapper>
        <LoginPage />
      </SuspenseWrapper>
    ),
  },
  {
    path: "/logout",
    element: (
      <SuspenseWrapper>
        <LogoutPage />
      </SuspenseWrapper>
    ),
  },
  {
    path: "/:tenantId",
    loader: tenantLoader,
    element: (
      <SuspenseWrapper>
        <TenantRootPage />
      </SuspenseWrapper>
    ),
    children: [
      {
        path: "/",
        element: (
          <SuspenseWrapper>
            <LandingPage />
          </SuspenseWrapper>
        ),
      },
      {
        path: "/visits",
        element: (
          <SuspenseWrapper>
            <VisitsPage />
          </SuspenseWrapper>
        ),
      },
      {
        path: "/update",
        element: (
          <SuspenseWrapper>
            <UpdateIncompleteVisit />
          </SuspenseWrapper>
        ),
      },
    ],
  },
  {
    element: "Default 404 Page",
  },
];
