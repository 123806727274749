import { useAppSelector } from "../redux/store";
import spearheadLogo from "../helpers/constant/spearheadLogo";
import useTranslations from "../helpers/scoped-translation";

const LoadingTenantPage = () => {
  const { tenantId } = useAppSelector((state) => state.app_info);
  const t = useTranslations();

  return (
    <div className='hero px-5'>
      <div className='hero-content flex-col lg:flex-row-reverse'>
        <img className='max-w-xs rounded-lg hidden md:inline-flex' src={spearheadLogo} />

        <div>
          <h1 className='text-5xl font-bold'>{t.theHiveAwaits()}</h1>
          {!tenantId ? (
            <p className='py-6'>{t.noTenantPleaseCheckLink()}</p>
          ) : (
            <>
              <p className='py-6'>{t.waitToLoadTenant()}</p>
              <span className='loading loading-dots loading-md' />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default LoadingTenantPage;
