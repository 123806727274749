import { type ITranslation } from "../../types/types";

const bengali: ITranslation = {
  tutorial: {
    stepOne: {
      newUsers: () => "নতুন ব্যবহারকারী",
      existingUsers: () => "বিদ্যমান ব্যবহারকারী",
      clickOnGetStarted: () =>
        ' "শুরু করুন" তে ক্লিক করলে, আপনাকে আমাদের অনুমোদন সেবা, Auth0 এ নিয়ে যাবে।',
      newUserNextSteps: () =>
        `একবার নিবন্ধন করার পর, Auth0 আপনাকে আবার TimeHive এ পুনর্নির্দেশ করবে।`,
      existingUserNextSteps: () =>
        `লগ ইন করার পর, Auth0 আপনাকে আবার TimeHive এ পুনর্নির্দেশ করবে। আপনি যদি ইতিমধ্যে লগ ইন থাকেন, তাহলে আপনি তাত্ক্ষণিকভাবে TimeHive এ ফিরে যাবেন।`,
    },
    clickIcon: () => "আপনার আইকনে ক্লিক করুন একটি ড্রপডাউন মেনু খুলতে।",
    useButtons: () => "আপনার নির্বাচন করার সহায়তা করতে এই বোতামগুলি ব্যবহার করুন।",
    clickToSelectVisits: () => "ক্লিক করুন যে আপনি নিশ্চিত করতে চান ভিজিটগুলি নির্বাচন করতে।",
    proceedConfirm: () => "নির্বাচিত ভিজিটগুলি নিশ্চিত করার প্রক্রিয়াটি প্রবেশ করুন।",
    onlyRedAsterixRequired: () => "লাল তারকা ছাড়া ফিল্ডগুলি প্রয়োজন নেই এবং ফাঁকা হতে পারে।",
    verifyYourInfo: () =>
      "আপনার তথ্যগুলি যাচাই করুন। পরিবর্তনের জন্য একটি প্রশাসকের সাথে যোগাযোগ করুন।",
    verifyPatientInfo: () =>
      "রোগীর তথ্য যাচাই করুন। পরিবর্তনের জন্য একটি প্রশাসকের সাথে যোগাযোগ করুন।",
    verifyPatientAddressAndId: () => "রোগীর ঠিকানা এবং আইডি যাচাই করুন বা প্রবেশ করুন।",
    verifyDateTimes: () => "ভিজিটের তারিখ এবং সময় যাচাই করুন।",
    checkDuties: () =>
      "আপনি যে প্রতিদিন (ভিজিট) নিশ্চিত করছেন তার জন্য দায়িত্ব প্রদান করা হয়েছে তা চেক করুন। আপনার এজেন্সির উপর নির্ভর করে, এটি দৃশ্যমান হতে পারে না।",
    mayNotBeVisible: () => "আপনার এজেন্সির উপর নির্ভর করে, এটি দৃশ্যমান হতে পারে না।",
    provideSignatures: () => "রোগী এবং নিজের জন্য স্বাক্ষর দিন।",
    checkDaysUnableToSign: () =>
      "রোগীর যে কোনও দিনের জন্য চেক করুন, এবং যে কারণে রোগীটি সাইন করতে অক্ষম ছিল তা ব্যাখ্যা করুন।",
    clickSubmitData: () =>
      "আপনি প্রবেশ করা ডেটা জমা দেওয়ার জন্য ক্লিক করুন এবং আপনার ভিজিটগুলি নিশ্চিত করুন।",
    downloadPDF: () => "আপনার টাইমশীটের একটি পিডিএফ কপি ডাউনলোড করতে এটি চেক করা রেখে দিন।",
    finalSubmit: () =>
      "কোন ফিরে যাওয়া নেই! আপনার ডেটা প্রেরণ করার জন্য এখানে ক্লিক করুন এবং আপনার ভিজিটগুলি নিশ্চিত করুন।",
  },
  twoCharCode: () => "bn",
  language: () => "ভাষা",
  languages: {
    eng: () => "ইংরেজি",
    spa: () => "স্পেনীয়",
    rus: () => "রুশ",
    kor: () => "কোরিয়ান",
    yue: () => "ক্যান্টনিজ",
    cmn: () => "ম্যান্ডারিন",
    ben: () => "বাংলা",
    ceb: () => "সেবুয়ানো",
    yid: () => "ইদিশ",
  },
  navigation: {
    home: () => "হোম",
    visits: () => "দেখাদেখি",
  },
  dropdown: {
    aboutToLeave: () => "অপেক্ষা করুন, আপনি চলে যাচ্ছেন!",
    confirmLogout: () => "আপনি কি নিশ্চিত যে আপনি লগ আউট করতে চান?",
  },
  actions: {
    ok: () => "ঠিক আছে",
    cancel: () => "বাতিল করুন",
    clear: () => "পরিষ্কার করুন",
    close: () => "বন্ধ করুন",
    remove: () => "মুছে ফেলুন",
    submit: () => "জমা দিন",
    save: () => "সংরক্ষণ করুন",
    savingTrailOff: () => "সংরক্ষণ হচ্ছে...",
    update: () => "আপডেট করুন",
    updatingTrailOff: () => "আপডেট হচ্ছে...",
    confirm: () => "অনুমোদন করুন",
    submittingTrailOff: () => "জমা দেওয়া হচ্ছে...",
    sync: () => "সিঙ্ক করুন",
    syncTrailOff: () => "সিঙ্ক করা হচ্ছে...",
    change: () => "পরিবর্তন করুন",
    back: () => "পেছনে যান",
    done: () => "সম্পন্ন",
    next: () => "পরবর্তী",
  },
  welcomeToTimeHive: () => "সময় মধুপানে স্বাগতম",
  welcomeToTimeHivePleaseUpdateInfo: () =>
    "সময় মধুপানে স্বাগতম, চালিয়ে যাওয়ার জন্য আপনার তথ্য আপডেট করুন",
  completeYourVisits: () => "আপনার দেখাদেখি সম্পন্ন করুন",
  getStarted: () => "শুরু করা হচ্ছে",
  noteExclamation: () => "মন্তব্য!",
  noSensitiveDataSaved: () =>
    "আমরা ব্যক্তিগত সনাক্তযোগ্য তথ্য সংরক্ষণ করি না। এই ইনপুটটি আমাদের ঈএইচআর সিস্টেমে আপনার প্রোফাইল সনাক্ত করার জন্য ব্যবহৃত হবে।",
  resetSSN: () => "এসএসএন রিসেট করুন",
  switchProfile: () => "এজেন্সি পরিবর্তন করুন",
  selectProfile: () => "এজেন্সি নির্বাচন করুন",
  noProfiles: () => "কোনও এজেন্সি নেই",
  noIncompleteVisits: () => "অসম্পূর্ণ দেখাদেখি নেই",
  login: () => "লগইন",
  loginToContinue: () => "চালিয়ে যাওয়ার জন্য লগইন করুন।",
  reload: () => "পুনরায় লোড করুন",
  logout: () => "লগ আউট",
  youAreLoggedOut: () => "আপনি লগ আউট হয়েছেন!",
  loginAgainToUse: () => "টাইমশীট ব্যবহার করতে আবার লগইন করুন",
  caregiverId: (caregiverId: string) => `যতিমণ্ডল আইডি: ${caregiverId}`,
  "timesheets/days": (number?: number) => `${number ?? ""} টাইমশীট/দিন`,
  redirecting: () => "পুনর্নির্দেশনা হচ্ছে",
  redirectingWithExclamation: () => "পুনর্নির্দেশনা!",
  sortByDay: () => "দিন অনুযায়ী বাছাই করুন",
  selectByWeek: () => "সপ্তাহ অনুযায়ী বাছাই করুন",
  updateSelectedVisits: (numberOfVisits: number) =>
    `${numberOfVisits} নির্বাচিত দেখাদেখি আপডেট করুন ${numberOfVisits > 1 ? "গুলি" : ""}`,
  fetchingVisits: () => "দেখাদেখি আনা হচ্ছে",
  myIncompleteVisits: () => "আমার অসম্পূর্ণ দেখাদেখি",
  patientWithName: (patientName: string) => `রোগী: ${patientName}`,
  visitDateWithDate: (visitDate: string) => `দেখাদেখির তারিখ: ${visitDate}`,
  timeVisitStarted: (time: string) => `দেখাদেখি শুরু হয়েছিল: ${time}`,
  timeVisitEnded: (time: string) => `দেখাদেখি শেষ হয়েছিল: ${time}`,
  notProvided: () => "প্রদান করা হয়নি",
  totalAccumulatedTimeWithColon: () => `মোট সংগ্রহিত সময়:`,
  hoursShort: () => "ঘণ্টা",
  minutesShort: () => "মিনিট",
  theHiveAwaits: () => "হাইভ অপেক্ষা করছে",
  noTenantPleaseCheckLink: () =>
    "কোনও লেনদেনকারী পাওয়া যাচ্ছে না। দয়া করে আপনার আইটি বিভাগের সাথে লিংকটি যাচাই করুন।",
  waitToLoadTenant: () => "আপনার লেনদেনকারী লোড হওয়ার জন্য দয়া করে অপেক্ষা করুন...",
  noSignatureFound: () => "স্বাক্ষর পাওয়া যায়নি",
  typeHere: () => "এখানে টাইপ করুন",
  sorryTryAgain: () => "দুঃখিত, আবার চেষ্টা করুন",
  dialogs: {
    escapeOrClickButtonToClose: () => "বন্ধ করতে ESC কী চাপুন অথবা নীচের বাটনটি ক্লিক করুন",
    unauthorized: {
      warningUnauthorized: () => "সতর্কতা: অননুমোদিত অ্যাক্সেস!",
      details: () =>
        "আপনি এই পৃষ্ঠায় অনুমোদিত নন। অনুগ্রহ করে সমর্থনের জন্য আপনার আইটি সংশ্লিষ্ট বিভাগের সাথে যোগাযোগ করুন।",
    },
    noTenant: {
      warningNoTenantFound: () => "সতর্কতা: URL-এ কোনও ভাড়াদার পাওয়া যায়নি!",
      details: () =>
        "এগিয়ে যাওয়ার আগে একটি ভাড়াদার নির্দিষ্ট করা আবশ্যক। আপনার আইটি দলের সাথে লিঙ্কটি যাচাই করুন।",
      enterIdentifier: () => "টেনেন্টের আইডি দিন",
    },
    resetSSN: {
      ssn: () => "এসএসএন",
      userIDNotFound: () => "ব্যবহারকারী আইডি পাওয়া যায়নি",
    },
    submitTimesheetOptions: {
      instructionsToSubmit: {
        part1: () => "যখন আপনি ক্লিক করেন",
        part2: () => "অনুমোদন",
        part3: () => "আপনার টাইমশীট পোস্ট হবে",
      },
      somethingWentWrong: () => "উফ! জমা দেওয়ার সময় কিছু সমস্যা হয়েছিল।",
      errorReference: (errorCode: string) => `রেফারেন্স#: ${errorCode}`,
      downloadPDF: () => "একটি PDF কপি ডাউনলোড করবেন?",
      errorGeneratingPDF: () => "পিডিএফ তৈরি করার সময় ত্রুটি। \nদয়া করে পরে আবার চেষ্টা করুন।",
      pleaseSelectPatient: () => "অনুগ্রহ করে একজন রোগী নির্বাচন করুন",
      noMoreThan7Days: () => "আপনি একই সময়ে শুধুমাত্র 7 দিন জমা দিতে পারেন",
      caregiverCodeRequired: () => "কর্মচারী নম্বর প্রয়োজন",
      unableToGeneratePDF: () => "টাইমশীট পিডিএফ তৈরি করা সম্ভব হয়নি",
      areYouSureTheDataYouEnteredIsCorrect: () => "আপনি কি নিশ্চিত যে আপনি দেওয়া তথ্যটি সঠিক?",
    },
  },
  days: {
    sunday: () => "রবিবার",
    monday: () => "সোমবার",
    tuesday: () => "মঙ্গলবার",
    wednesday: () => "বুধবার",
    thursday: () => "বৃহস্পতিবার",
    friday: () => "শুক্রবার",
    saturday: () => "শনিবার",
  },
  timesheet: {
    xDutiesAreRequiredInThisSection: (minimum) =>
      `এই বিভাগে অন্তত ${minimum.toString()} দায়িত্ব প্রয়োজন`,
    checkOffDaysPatientCannotSign: () =>
      "Please check off all days when the patient could not sign",
    hhaDutyForm: () => "হোম হেলথ এইড ডিউটি ফরম",
    patientSignature: () => "রোগীর স্বাক্ষর",
    hhaSignature: () => "হোম হেলথ এইড স্বাক্ষর",
    signHere: () => "এখানে সাইন করুন",
    patientUnableToSign: () => "রোগী সাইন করতে অক্ষম",
    whyPatientCannotSign: () => "অনুগ্রহ করে উল্লেখ করুন রোগী কেনো সাইন করতে পারেননি",
    whyWithQuestionMark: (): "কেন?" => "কেন?",
    noDuplicateDaysOrDates: () => "ডুপ্লিকেট দিন বা তারিখ অনুমোদিত নয়",
    pleaseFillAllFields: () => "সব ক্ষেত্র পূরণ করুন",
    atLeastOneLine: () => "কমপক্ষে একটি লাইন প্রয়োজন",
    atLeastOneVisit: () => "সাবমিট করার জন্য অনুগ্রহ করে কমপক্ষে একটি দেখাদেখি নির্বাচন করুন।",
    dateAndTime: () => "তারিখ ও সময়",
    timeCannotBeInFuture: () => "সময় ভবিষ্যতে থাকতে পারে না",
    endingDayOfWeek: (endingDayOfWeek: string) => `সমাপ্তি ${endingDayOfWeek}`,
    date: () => "তারিখ",
    day: () => "দিন",
    timeArrived: () => "রোগীর সাথে পৌঁছার সময়",
    timeLeft: () => "রোগী থেকে বেরিয়ে গেলের সময়",
    totalHours: () => "মোট ঘন্টা কাজ",
    overnightShiftWithQuestionMark: () => "রাতের পরিবর্তন?",
    scheduledTime: (time: string) => `নির্ধারিত: ${time}`,
    duties: () => "দায়িত্ব",
    checkOffDuties: () => "সমস্ত পূর্ণ দায়িত্ব চেক করুন",
    information: () => "তথ্য",
    caregiverName: () => "কর্মচারীর নাম",
    socialSecurityNumber: () => "সোশ্যাল সিকিউরিটি নম্বর",
    caregiverCode: () => "কর্মচারী নম্বর",
    patientName: () => "রোগীর নাম",
    phone: () => "ফোন",
    year: () => "বছর",
    patientAddress: () => "রোগীর ঠিকানা",
    patientId: () => "রোগী আইডি",
    missing: {
      hhaSignature: () => "HHA স্বাক্ষর অনুপস্থিত।",
      patientSignature: () => "রোগীর স্বাক্ষর অনুপস্থিত।",
      patientId: () => "রোগী আইডি অনুপস্থিত।",
      patientName: () => "রোগীর নাম অনুপস্থিত।",
      patientAddress: () => "রোগীর ঠিকানা অনুপস্থিত।",
      caregiverName: () => "কর্মচারীর নাম অনুপস্থিত।",
      caregiverCode: () => "কর্মচারী নম্বর অনুপস্থিত।",
      pocCompliance: () => "প্রয়োজনীয় দায়িত্ব চেক অফ নেই / POC Compliance",
      timesheetReason: () => "টাইমশীট কারণ অনুপস্থিত.",
    },
    signInBoxBelow: () => "নীচের বাক্সে সাইন করুন",
    timesheetReason: () => "টাইমশীট কারণ",
    reason: () => "কারণ",
    select: () => "নির্বাচন করুন",
  },
  errors: {
    caregiverIdRequired: () => "যত্নকারী আইডি প্রয়োজন",
    firstNameRequired: () => "নাম প্রয়োজন",
    lastNameRequired: () => "শেষ নাম প্রয়োজন",
    ssnRequired: () => "এসএসএন প্রয়োজন",
    dayRequired: () => "দিন প্রয়োজন",
    dateRequired: () => "তারিখ প্রয়োজন",
    timeCannotBeInTheFuture: () => "সময় ভবিষ্যতে হতে পারে না",
    totalHrsRequired: () => "মোট ঘন্টা প্রয়োজন",
    greaterThan0: () => "মোট ঘন্টা 0 এর চেয়ে বড় হতে হবে",
    lessThan24hrs: () => "মোট ঘন্টা 24 এর কম হতে হবে",
    mustBeInteger: () => "মোট ঘন্টা একটি পূর্ণসংখ্যা হতে হবে",
    ssnFormat: () => "এসএসএন এই ফরম্যাটে হতে হবে 123-45-6789",
    ssnMustBe9Digits: () => "এসএসএন অবশ্যই 9 টি সংখ্যা হতে হবে",
    caregiverNameRequired: () => "কর্মচারীর নাম প্রয়োজন",
    caregiverCodeRequired: () => "কর্মচারী কোড প্রয়োজন",
    patientNameRequired: () => "রোগীর নাম প্রয়োজন",
    invalidYear: () => "একটি বৈধ বছর লিখুন",
    patientAddressRequired: () => "রোগীর ঠিকানা প্রয়োজন",
    patientIdRequired: () => "রোগী আইডি প্রয়োজন",
    invalidPatientId: () => "একটি বৈধ রোগী আইডি লিখুন",
    phoneNumberRequired: () => "ফোন নাম্বার প্রয়োজন",
  },
  mainainance: {
    maintainanceInProgress: () => "মেইনটেইনেন্স চলমান",
    weAreApplyingUpgradesOperationalSoon: () =>
      "হাইভটি পরিষ্কার এবং প্রসারিত হচ্ছে, আমরা শীঘ্রই অপারেশনাল হব",
    thankYouForYourPatience: () => "আপনার ধৈর্যের জন্য ধন্যবাদ",
    maintainanceWillBePerformed: (startTime, endTime) =>
      "সময়হাইভটি পরিকল্পিত মেইনটেইনেন্সের জন্য অপারেশনাল নয় " + startTime + " থেকে " + endTime,
  },
};

export default bengali;
