import { createSlice, type PayloadAction } from "@reduxjs/toolkit";

export const SIGNATURE_SLICE_NAME = "signature";

export interface ISignature {
  pc_signature_url: string;
  hha_signature_url: string;
}

const signatureInitialState: ISignature = {
  pc_signature_url: "",
  hha_signature_url: "",
};

export const signatureSlice = createSlice({
  name: SIGNATURE_SLICE_NAME,
  initialState: signatureInitialState,
  reducers: {
    // Add your reducers here
    setSignature: (state, action: PayloadAction<ISignature>) => {
      return {
        ...state,
        ...action.payload,
      };
    },
    setPCSignature: (state, action: PayloadAction<ISignature["pc_signature_url"]>) => {
      return {
        ...state,
        pc_signature_url: action.payload,
      };
    },
    setHHASignature: (state, action: PayloadAction<ISignature["hha_signature_url"]>) => {
      return {
        ...state,
        hha_signature_url: action.payload,
      };
    },
    resetSignatureState: () => {
      return signatureInitialState;
    },
  },
});

export default signatureSlice.reducer;

export const { setSignature, setHHASignature, setPCSignature, resetSignatureState } =
  signatureSlice.actions;
