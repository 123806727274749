import { useCallback } from "react";
import { type UseQueryResult, useQuery } from "@tanstack/react-query";
import { getUser } from "../requests/user.auth0.request";
import { type Auth0UserType, auth0UserSchema } from "../schemas/auth0.user.schema";
import { useAuthCheckpoint } from "../../hooks/useAuthCheckpoint";

export const useAuth0User = (userId: string): UseQueryResult<Auth0UserType> => {
  const authCheckpoint = useAuthCheckpoint();

  return useQuery({
    enabled: authCheckpoint?.auth0Management.isAuthenticated && !!userId,
    queryKey: ["auth0User"],
    queryFn: async () => await getUser(userId),
    refetchOnWindowFocus: false,
    select: useCallback((data: any) => {
      return auth0UserSchema.validateSync(data, {
        abortEarly: false,
        stripUnknown: true,
        strict: true,
      });
    }, []),
    onError: () => {},
    useErrorBoundary: useCallback(() => {
      // toast.error(`Something went wrong! \n Please reset your SSN.`, {
      //   id: "auth0User",
      // });
      return false;
    }, []),
  });
};
