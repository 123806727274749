import React from "react";
import ReactDOM from "react-dom/client";
import { QueryClientProvider, QueryClient } from "@tanstack/react-query";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import store from "./redux/store";
import { Auth0Provider } from "@auth0/auth0-react";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import AuthCheckpointContext from "./context/AuthCheckpointContext";
import LanguageContext from "./context/LanguageContext";
import MaintenanceCheckpoint from "./context/MaintenanceCheckpoint";
import URLFormatCheckpoint from "./context/URLFormatCheckpoint";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

const queryClient = new QueryClient();

root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <MaintenanceCheckpoint>
        <URLFormatCheckpoint>
          <Auth0Provider
            clientId={window._env_.REACT_APP_AUTH0_CLIENT_ID}
            domain={window._env_.REACT_APP_AUTH0_DOMAIN}
            authorizationParams={{
              redirect_uri: window.location.origin,
              audience: window._env_.REACT_APP_TIMEHIVE_API_AUDIENCE,
            }}
          >
            <Provider store={store}>
              <AuthCheckpointContext>
                <LanguageContext>
                  <App />
                </LanguageContext>
              </AuthCheckpointContext>
            </Provider>
          </Auth0Provider>
        </URLFormatCheckpoint>
      </MaintenanceCheckpoint>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
