import { useState } from "react";
import { Toggle } from "react-daisyui";
import useTranslations from "../../helpers/scoped-translation";

type UserType = "new" | "existing";

const StepOne = () => {
  const [userType, setUserType] = useState<UserType>("new");
  const t = useTranslations();

  return (
    <>
      <div className='flex-row mb-5' style={{ borderBottom: "solid black" }}>
        <strong className={userType === "existing" ? "text-gray-400" : ""}>
          {t.tutorial.stepOne.newUsers()}
        </strong>
        <Toggle
          className='m-1'
          checked={userType === "existing"}
          onChange={() => {
            setUserType((t) => (t === "new" ? "existing" : "new"));
          }}
        />
        <strong className={userType === "new" ? "text-gray-400" : ""}>
          {t.tutorial.stepOne.existingUsers()}
        </strong>
      </div>
      <h4 className='font-bold'>{t.tutorial.stepOne.clickOnGetStarted()}</h4>
      <br />
      <p>
        {userType === "new"
          ? t.tutorial.stepOne.newUserNextSteps()
          : t.tutorial.stepOne.existingUserNextSteps()}
      </p>
    </>
  );
};

export default StepOne;
