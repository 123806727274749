import { useAuth0 } from "@auth0/auth0-react";
import useTranslations from "../../helpers/scoped-translation";

const UnauthorizedDialog = (): JSX.Element => {
  const { logout } = useAuth0();
  const t = useTranslations();

  return (
    <dialog id='unauthorize_modal' className='modal'>
      <div className='modal-box'>
        <form method='dialog'>
          <h3 className='font-bold text-lg'>{t.dialogs.unauthorized.warningUnauthorized()}</h3>
          <p className='py-4'>{t.dialogs.unauthorized.details()}</p>
          {/* show a message */}

          {/* if there is a button in form, it will close the modal */}
          <div className='modal-action'>
            <button
              type='button'
              className='btn'
              onClick={() => {
                window.location.reload();
              }}
            >
              {t.reload()}
            </button>
            <button
              type='button'
              className='btn btn-error'
              onClick={() => {
                logout().catch(console.error);
              }}
            >
              {t.logout()}
            </button>
          </div>
        </form>
      </div>
    </dialog>
  );
};

export default UnauthorizedDialog;
