import { useAuth0 } from "@auth0/auth0-react";
import { Link } from "@tanstack/react-location";
import { useCallback, useContext, useEffect } from "react";
import classNames from "classnames";
import { useAppDispatch, useAppSelector } from "../redux/store";
import { resetAppInfoAction } from "../redux/features/app-info.slice";
import translations from "../helpers/translations";
import { LanguageContext } from "../context/LanguageContext";
import "moment/locale/es";
import "moment/locale/ko";
import "moment/locale/ru";
import moment from "moment";
import { useAuth0User } from "../services/queries/user.auth0.query";
import useTranslations from "../helpers/scoped-translation";
import LanguageSelector from "./LanguageSelector";
import TutorialTrigger from "./tutorial/TutorialTrigger";
import spearheadLogo from "../helpers/constant/spearheadLogo";

function HeaderNavigation(): JSX.Element {
  const { user, logout, isAuthenticated, isLoading } = useAuth0();
  const { data: auth0User } = useAuth0User(user?.sub ?? "");
  const { defaultProfile } = useAppSelector((state) => state.caregiver_info);
  const { logo, tenantId } = useAppSelector((state) => state.app_info);
  const dispatch = useAppDispatch();
  const languageContext = useContext(LanguageContext);
  const t = useTranslations();

  const handleLogout = useCallback(() => {
    logout()
      .then(() => dispatch(resetAppInfoAction()))
      .catch(console.error);
  }, []);

  useEffect(() => {
    const preferredLanguage = auth0User?.user_metadata.preferredLanguage as
      | keyof typeof translations
      | null;
    if (!languageContext && !preferredLanguage) moment.locale("en");
    else if (
      preferredLanguage &&
      !!languageContext &&
      languageContext.language !== preferredLanguage
    ) {
      languageContext.setLanguage(preferredLanguage);
    }
    if (
      !!languageContext &&
      moment.locale() !== translations[languageContext.language].twoCharCode()
    ) {
      moment.locale(translations[languageContext.language].twoCharCode());
    }
  }, [languageContext, auth0User]);

  return (
    <>
      <div className='navbar bg-base-100 container mx-auto'>
        <div className='navbar-start'>
          {isAuthenticated && (
            <div className='dropdown flex lg:hidden'>
              <label tabIndex={0} className='btn btn-ghost btn-circle'>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  className='h-5 w-5'
                  fill='none'
                  viewBox='0 0 24 24'
                  stroke='currentColor'
                >
                  <path
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    strokeWidth='2'
                    d='M4 6h16M4 12h16M4 18h7'
                  />
                </svg>
              </label>
              <ul
                tabIndex={0}
                className='menu menu-sm dropdown-content mt-3 p-2 shadow bg-base-100 rounded-box w-52'
              >
                <li>
                  <Link to={`${tenantId}/`}>{t.navigation.home()}</Link>
                </li>
                <li>
                  <Link to={`${tenantId}/visits`}>{t.navigation.visits()}</Link>
                </li>
              </ul>
            </div>
          )}
          <img src={logo || spearheadLogo} className='h-16 aspect-auto' />
        </div>
        <TutorialTrigger />
        {isAuthenticated && tenantId && (
          <div className='navbar-center hidden lg:flex'>
            <ul className='menu menu-horizontal px-1 gap-2'>
              <li>
                <Link to={`${tenantId}/`}>{t.navigation.home()}</Link>
              </li>
              <li>
                <Link to={`${tenantId}/visits`}>{t.navigation.visits()}</Link>
              </li>
            </ul>
          </div>
        )}
        {!isAuthenticated && tenantId ? (
          <div className='navbar-end'>
            <LanguageSelector />
          </div>
        ) : (
          <div className={classNames("flex-none navbar-end", {})}>
            <div className='dropdown dropdown-end'>
              <div className='flex items-center'>
                <div className='flex flex-col mr-2'>
                  <label className='font-bold text-end md:block hidden'>{user?.nickname}</label>
                  <label className='font-bold ml-2 text-end text-primary md:inline-flex hidden'>
                    {user?.name}
                  </label>
                </div>
                {isLoading && <span className='loading loading-spinner'></span>}
                <label
                  tabIndex={0}
                  className={classNames("btn btn-ghost btn-circle avatar", {
                    hidden: !isAuthenticated,
                  })}
                >
                  <div className='w-10 rounded-full joyride-step-1'>
                    <img src={user?.picture} alt='profile_picture' />
                  </div>
                </label>
              </div>

              <ul
                tabIndex={0}
                className='menu menu-compact dropdown-content mt-3 p-2 shadow bg-base-100 rounded-box max-w-54'
                style={{ zIndex: 99999 }}
              >
                <li className='grid grid-cols-1 md:grid-cols-2 gap-2'>
                  <LanguageSelector />
                </li>
                <div className='divider' />
                <li className='pointer-events-none'>
                  <div className='flex flex-wrap'>
                    <label className='font-bold'>{defaultProfile?.offices}</label>
                  </div>
                </li>
                <li>
                  <label
                    onClick={() => {
                      window?.switch_profile_modal?.showModal();
                    }}
                  >
                    {t.switchProfile()}
                  </label>
                </li>
                <li>
                  <label
                    onClick={() => {
                      window?.reset_ssn_modal?.showModal();
                    }}
                  >
                    {t.resetSSN()}
                  </label>
                </li>
                <li>
                  <label htmlFor='logout-modal'>{t.logout()}</label>
                </li>
              </ul>
            </div>
          </div>
        )}

        {/* Put this part before </body> tag */}
        <input type='checkbox' id='logout-modal' className='modal-toggle' />
        <div className='modal modal-bottom sm:modal-middle'>
          <div className='modal-box'>
            <h2 className='card-title'>{t.dropdown.aboutToLeave()}</h2>
            <p>{t.dropdown.confirmLogout()}</p>
            <div className='card-actions justify-end mt-5'>
              <label htmlFor='logout-modal' className='btn btn-ghost'>
                {t.actions.cancel()}
              </label>
              <label
                onClick={() => {
                  handleLogout();
                }}
                className='btn btn-ghost ring-2 ring-primary active:bg-primary active:text-base-content'
                htmlFor='logout-modal'
              >
                {t.logout()}
              </label>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default HeaderNavigation;
