import { type ITranslation } from "../../types/types";

const english: ITranslation = {
  tutorial: {
    stepOne: {
      newUsers: () => "New Users",
      existingUsers: () => "Existing Users",
      clickOnGetStarted: () =>
        'Clicking on "Get Started" will direct you to our authentication service, Auth0.',
      newUserNextSteps: () => `After signing up, Auth0 will redirect you back to TimeHive.`,
      existingUserNextSteps: () =>
        `After logging in, Auth0 will redirect you back to TimeHive. If you are already logged in, you will be immediately redirected back to TimeHive.`,
    },
    mayNotBeVisible: () => "Depending on your agency, this may or may not be visible.",
    clickIcon: () => "Click on your icon to open a dropdown menu.",
    useButtons: () => "Use these buttons to assist you in selecting visits to confirm.",
    clickToSelectVisits: () => "Click to select visits that you wish to confirm.",
    proceedConfirm: () => "Proceed to confirming selected visits.",
    onlyRedAsterixRequired: () => "Fields without a red asterix are not required and may be empty.",
    verifyYourInfo: () =>
      "Verify that your information is correct. Contact an administrator for changes.",
    verifyPatientInfo: () =>
      "Verify that patient information is correct. Contact an administrator for changes.",
    verifyPatientAddressAndId: () => "Verify or enter the patient's address and ID.",
    verifyDateTimes: () => "Verify the date and times of the visit.",
    checkDuties: () =>
      "Check off the duties you performed for each day (visit) you are confirming.",
    provideSignatures: () => "Provide a signature for the patient and yourself.",
    checkDaysUnableToSign: () =>
      "Check off and explain the reason why the patient was unable to sign.",
    clickSubmitData: () => "Click to submit the data you entered and confirm your visits.",
    downloadPDF: () => "Leave this checked to download a PDF copy of your timesheet.",
    finalSubmit: () =>
      "There is no going back! Click here to send your data off and confirm your visit.",
  },
  twoCharCode: () => "en",
  language: () => "Language",
  languages: {
    eng: () => "English",
    spa: () => "Spanish",
    rus: () => "Russian",
    kor: () => "Korean",
    yue: () => "Cantonese",
    cmn: () => "Mandarin",
    ben: () => "Bengali",
    ceb: () => "Cebuano",
    yid: () => "Yiddish",
  },
  navigation: {
    home: () => "Home",
    visits: () => "Visits",
  },
  dropdown: {
    aboutToLeave: () => "Wait, you're about to leave!",
    confirmLogout: () => "Are you sure you want to log out?",
  },
  actions: {
    ok: () => "Ok",
    cancel: () => "Cancel",
    clear: () => "Clear",
    close: () => "Close",
    remove: () => "Remove",
    submit: () => "Submit",
    save: () => "Save",
    savingTrailOff: () => "Saving...",
    update: () => "Update",
    updatingTrailOff: () => "Updating...",
    confirm: () => "Confirm",
    submittingTrailOff: () => "Submitting...",
    sync: () => "Sync",
    syncTrailOff: () => "Syncing...",
    change: () => "Change",
    back: () => "Back",
    done: () => "Done",
    next: () => "Next",
  },
  welcomeToTimeHive: () => "Welcome to TimeHive",
  welcomeToTimeHivePleaseUpdateInfo: () =>
    "Welcome to TimeHive, Please update your information below to continue",
  completeYourVisits: () => "Complete your visits",
  getStarted: () => "Get Started",
  noteExclamation: () => "Note!",
  noSensitiveDataSaved: () =>
    "We do not save personal identifiable information. This input will be used to identify your profile in our EHR system.",
  resetSSN: () => "Reset SSN",
  switchProfile: () => "Change Agency",
  selectProfile: () => "Select Agency",
  noProfiles: () => "No Agencies",
  noIncompleteVisits: () => "No Incomplete Visits",
  login: () => "Login",
  loginToContinue: () => "Login to continue now.",
  reload: () => "Reload",
  logout: () => "Logout",
  youAreLoggedOut: () => "You are logged out!",
  loginAgainToUse: () => "Login again to use Timesheet",
  caregiverId: (caregiverId) => `Caregiver ID: ${caregiverId}`,
  "timesheets/days": (number) => `${number ?? ""} timesheets/days`,
  redirecting: () => "Redirecting",
  redirectingWithExclamation: () => "Redirecting!",
  sortByDay: () => "Sort by Day",
  selectByWeek: () => "Select by Week",
  updateSelectedVisits: (numberOfVisits) =>
    `Update ${numberOfVisits} Selected Visit${numberOfVisits > 1 ? "s" : ""}`,
  fetchingVisits: () => "Fetching Visits",
  myIncompleteVisits: () => "My Incomplete Visits",
  patientWithName: (patientName) => `Patient: ${patientName}`,
  visitDateWithDate: (visitDate) => `Visit Date: ${visitDate}`,
  timeVisitStarted: (time) => `Time Visit Started: ${time}`,
  timeVisitEnded: (time) => `Time Visit Ended: ${time}`,
  notProvided: () => "Not Provided",
  totalAccumulatedTimeWithColon: () => `Total Accumulated Time:`,
  hoursShort: () => "hrs",
  minutesShort: () => "min",
  theHiveAwaits: () => "The Hive Awaits",
  noTenantPleaseCheckLink: () => "No tenant found. Please verify the link with your IT department.",
  waitToLoadTenant: () => "Please stand by while we load your tenant...",
  noSignatureFound: () => "No signature found",
  typeHere: () => "Type here",
  sorryTryAgain: () => "Sorry, try again",
  dialogs: {
    escapeOrClickButtonToClose: () => "Press ESC key or click the button below to close",
    unauthorized: {
      warningUnauthorized: () => "Warning: Unauthorized Access!",
      details: () =>
        "You are not authorized to access this page. Please contact your IT for support.",
    },
    noTenant: {
      warningNoTenantFound: () => "Warning: No Tenant Found in the URL!",
      details: () =>
        "A tenant must be specified before continuing. Please verify the link with your IT team.",
      enterIdentifier: () => "Enter ID for tenant",
    },
    resetSSN: {
      ssn: () => "SSN",
      userIDNotFound: () => "User ID not found",
    },
    submitTimesheetOptions: {
      instructionsToSubmit: {
        part1: () => "When you click",
        part2: () => "Confirm",
        part3: () => "your timesheet will be posted",
      },
      somethingWentWrong: () => "Oops! Something went wrong during submission.",
      errorReference: (errorCode) => `Reference#: ${errorCode}`,
      downloadPDF: () => "Download a PDF copy?",
      errorGeneratingPDF: () => "Error generating PDF. \nPlease try again later.",
      pleaseSelectPatient: () => "Please select a patient",
      noMoreThan7Days: () => "You can only submit 7 days at a time",
      caregiverCodeRequired: () => "Caregiver code is required",
      unableToGeneratePDF: () => "Unable to Generate Timesheet PDF",
      areYouSureTheDataYouEnteredIsCorrect: () => "Are you sure the data you entered is correct?",
    },
  },
  days: {
    sunday: () => "Sunday",
    monday: () => "Monday",
    tuesday: () => "Tuesday",
    wednesday: () => "Wednesday",
    thursday: () => "Thursday",
    friday: () => "Friday",
    saturday: () => "Saturday",
  },
  timesheet: {
    xDutiesAreRequiredInThisSection: (minimum) =>
      `At least ${minimum.toString()} duties are required in this section`,
    hhaDutyForm: () => "HOME HEALTH AIDE DUTY FORM",
    patientSignature: () => "Patient Signature",
    hhaSignature: () => "Home Health Aide Signature",
    signHere: () => "Sign Here",
    patientUnableToSign: () => "PATIENT UNABLE TO SIGN",
    whyPatientCannotSign: () => "Please specify why patient can't sign",
    whyWithQuestionMark: () => "Why?",
    checkOffDaysPatientCannotSign: () =>
      "Please check off all days when the patient could not sign",
    noDuplicateDaysOrDates: () => "Duplicate days or dates are not allowed",
    pleaseFillAllFields: () => "Please fill all fields",
    atLeastOneLine: () => "At least one line required",
    atLeastOneVisit: () => "Please select at least one visit to submit.",
    dateAndTime: () => "DATE & TIME",
    timeCannotBeInFuture: () => "Time cannot be in the future",
    endingDayOfWeek: (endingDayOfWeek) => `Ending ${endingDayOfWeek}`,
    date: () => "Date",
    day: () => "Day",
    timeArrived: () => "Time Arrived at Patient",
    timeLeft: () => "Time Left Patient",
    totalHours: () => "Total Hours Worked",
    overnightShiftWithQuestionMark: () => "Overnight Shift?",
    scheduledTime: (time) => `Scheduled: ${time}`,
    duties: () => "DUTIES",
    checkOffDuties: (min) =>
      "Please check off all duties completed." +
      (min ? "At least " + min.toString() + " overall are required by your agency" : ""),
    information: () => "INFORMATION",
    caregiverName: () => "Caregiver Name",
    socialSecurityNumber: () => "Social Security Number",
    caregiverCode: () => "Caregiver Code",
    patientName: () => "Patient Name",
    phone: () => "Phone",
    year: () => "Year",
    patientAddress: () => "Patient Address",
    patientId: () => "Patient ID",
    timesheetReason: () => "Timesheet Reason",
    reason: () => "Reason",
    select: () => "Select",
    missing: {
      hhaSignature: () => "HHA Signature is missing.",
      patientSignature: () => "Patient Signature is missing.",
      patientId: () => "Patient ID is missing.",
      patientName: () => "Patient Name is missing.",
      patientAddress: () => "Patient Address is missing.",
      caregiverName: () => "Caregiver Name is missing.",
      caregiverCode: () => "Caregiver Number is missing.",
      pocCompliance: () => "Required duties not checked off / POC Compliance",
      timesheetReason: () => "Timesheet Reason is missing.",
    },
    signInBoxBelow: () => "Please sign in the box below",
  },
  errors: {
    caregiverIdRequired: () => "Caregiver ID is required",
    firstNameRequired: () => "First name is required",
    lastNameRequired: () => "Last name is required",
    ssnRequired: () => "SSN is required",
    dayRequired: () => "Day is required",
    dateRequired: () => "Date is required",
    timeCannotBeInTheFuture: () => "Time cannot be in the future",
    totalHrsRequired: () => "Total hours worked is required",
    greaterThan0: () => "Total hours worked must be greater than 0",
    lessThan24hrs: () => "Total hours worked must be less than 24 hours",
    mustBeInteger: () => "Total hours worked must be an integer",
    ssnFormat: () => "SSN must be in the format of 123-45-6789",
    ssnMustBe9Digits: () => "SSN must be exactly 9 digits",
    caregiverNameRequired: () => "Employee name is required",
    caregiverCodeRequired: () => "Employee code is required",
    patientNameRequired: () => "Patient name is required",
    invalidYear: () => "Enter a valid year",
    patientAddressRequired: () => "Patient address is required",
    patientIdRequired: () => "Patient ID is required",
    invalidPatientId: () => "Enter a valid Patient ID",
    phoneNumberRequired: () => "Phone Number is required",
  },
  mainainance: {
    maintainanceInProgress: () => "Maintainance in Progress",
    weAreApplyingUpgradesOperationalSoon: () =>
      "The Hive is being cleaned up and expanded, we will be operational shortly",
    thankYouForYourPatience: () => "Thank you for your patience",
    maintainanceWillBePerformed: (startTime, endTime) =>
      "TimeHive will be inoperational for scheduled maintanance between " +
      startTime +
      " and " +
      endTime,
  },
};

export default english;
