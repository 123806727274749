import { createSlice, type PayloadAction } from "@reduxjs/toolkit";

export const DATE_TIME_FORM_SLICE_NAME = "date_time_form";

export interface IDateTimeForm {
  visitId: string;
  day: string;
  date: string;
  timeArrived: string;
  timeLeft: string;
  totalHoursWorked: number;
  overnight: boolean;
}

const initialFormState: IDateTimeForm[] = [];

export const dateTimeFormSlice = createSlice({
  name: DATE_TIME_FORM_SLICE_NAME,
  initialState: initialFormState,
  reducers: {
    addDateTimeForm: (state, action: PayloadAction<IDateTimeForm>) => {
      state.push(action.payload);
      return state;
    },
    setDateTimeForm: (state, action: PayloadAction<IDateTimeForm[]>) => {
      state = action.payload;
      return state;
    },
    clearDateTimeForm: (state) => {
      state.length = 0;
      return state;
    },
  },
});

export default dateTimeFormSlice.reducer;

export const { addDateTimeForm, setDateTimeForm, clearDateTimeForm } = dateTimeFormSlice.actions;
