import { type Step } from "react-joyride";
import StepOne from "./StepOne";
import { type ITranslation } from "../../types/types";

const steps = (t: ITranslation): Step[] => [
  { target: ".joyride-step-0", content: <StepOne /> },
  {
    target: ".joyride-step-1",
    content: t.tutorial.clickIcon(),
  },
  {
    target: ".joyride-step-2",
    content: t.tutorial.useButtons(),
  },
  {
    target: ".joyride-step-3",
    content: t.tutorial.clickToSelectVisits(),
  },
  {
    target: ".joyride-step-4",
    content: t.tutorial.proceedConfirm(),
  },
  {
    target: ".joyride-step-5",
    content: (
      <p>
        {t.tutorial.verifyYourInfo()}
        <br />
        <br />
        {t.tutorial.onlyRedAsterixRequired()}
      </p>
    ),
  },
  {
    target: ".joyride-step-6",
    content: (
      <p>
        {t.tutorial.verifyPatientInfo()}
        <br />
        <br />
        {t.tutorial.onlyRedAsterixRequired()}
      </p>
    ),
  },
  {
    target: ".joyride-step-7",
    content: t.tutorial.verifyPatientAddressAndId(),
  },
  {
    target: ".joyride-step-8",
    content: t.tutorial.verifyDateTimes(),
  },
  {
    target: ".joyride-step-9",
    content: (
      <p>
        {t.tutorial.checkDuties()}
        <br />
        <br />
        {t.tutorial.mayNotBeVisible()}
      </p>
    ),
  },
  {
    target: ".joyride-step-10",
    content: t.tutorial.provideSignatures(),
  },
  {
    target: ".joyride-step-11",
    content: t.tutorial.checkDaysUnableToSign(),
  },
  {
    target: ".joyride-step-12",
    content: t.tutorial.clickSubmitData(),
  },
  {
    target: ".joyride-step-13",
    content: t.tutorial.downloadPDF(),
    styles: { options: { zIndex: 1000 } },
  },
  {
    target: ".joyride-step-14",
    content: t.tutorial.finalSubmit(),
    styles: { options: { zIndex: 1000 } },
  },
];

export default steps;
