import { type ITranslation } from "../../types/types";

const spanish: ITranslation = {
  tutorial: {
    stepOne: {
      newUsers: () => "Nuevos Usuarios",
      existingUsers: () => "Usuarios Existentes",
      clickOnGetStarted: () =>
        'Al hacer clic en "Comenzar" te dirigirá a nuestro servicio de autenticación, Auth0.',
      newUserNextSteps: () => `Después de registrarte, Auth0 te redirigirá de vuelta a TimeHive.`,
      existingUserNextSteps: () =>
        `Después de iniciar sesión, Auth0 te redirigirá de vuelta a TimeHive. Si ya iniciaste sesión, serás redirigido inmediatamente de vuelta a TimeHive.`,
    },
    clickIcon: () => "Haz clic en tu icono para abrir un menú desplegable.",
    useButtons: () => "Usa estos botones para ayudarte a seleccionar visitas para confirmar.",
    clickToSelectVisits: () => "Haz clic para seleccionar las visitas que deseas confirmar.",
    proceedConfirm: () => "Procede a confirmar las visitas seleccionadas.",
    onlyRedAsterixRequired: () =>
      "Los campos sin un asterisco rojo no son obligatorios y pueden estar vacíos.",
    verifyYourInfo: () =>
      "Verifica que tu información sea correcta. Contacta a un administrador para realizar cambios.",
    verifyPatientInfo: () =>
      "Verifica que la información del paciente sea correcta. Contacta a un administrador para realizar cambios.",
    verifyPatientAddressAndId: () => "Verifica o ingresa la dirección y el ID del paciente.",
    verifyDateTimes: () => "Verifica la fecha y hora de la visita.",
    checkDuties: () =>
      "Marca las tareas que realizaste para cada día (visita) que estás confirmando. Dependiendo de tu agencia, esto puede ser visible o no.",
    mayNotBeVisible: () => "Dependiendo de tu agencia, esto puede ser visible o no.",
    provideSignatures: () => "Proporciona una firma para el paciente y para ti mismo.",
    checkDaysUnableToSign: () =>
      "Marca y explica el motivo de los días en los que el paciente no pudo firmar.",
    clickSubmitData: () => "Haz clic para enviar los datos que ingresaste y confirmar tus visitas.",
    downloadPDF: () => "Deja marcado para descargar una copia en PDF de tu hoja de horas.",
    finalSubmit: () =>
      "¡No hay vuelta atrás! Haz clic aquí para enviar tus datos y confirmar tu visita.",
  },
  twoCharCode: () => "es",
  language: () => "Idioma",
  languages: {
    eng: () => "Inglés",
    spa: () => "Español",
    rus: () => "Ruso",
    kor: () => "Coreano",
    yue: () => "Cantonés",
    cmn: () => "Mandarín",
    ben: () => "Bengalí",
    ceb: () => "Cebuano",
    yid: () => "ídish",
  },
  navigation: {
    home: () => "Inicio",
    visits: () => "Visitas",
  },
  dropdown: {
    aboutToLeave: () => "¡Espera, estás a punto de salir!",
    confirmLogout: () => "¿Estás seguro de que quieres cerrar sesión?",
  },
  actions: {
    ok: () => "Aceptar",
    cancel: () => "Cancelar",
    clear: () => "Limpiar",
    close: () => "Cerrar",
    remove: () => "Eliminar",
    submit: () => "Enviar",
    save: () => "Guardar",
    savingTrailOff: () => "Guardando...",
    update: () => "Actualizar",
    updatingTrailOff: () => "Actualizando...",
    confirm: () => "Confirmar",
    submittingTrailOff: () => "Enviando...",
    sync: () => "Sincronizar",
    syncTrailOff: () => "Sincronizando...",
    change: () => "Cambiar",
    back: () => "Volver",
    done: () => "Hecho",
    next: () => "junto a",
  },
  welcomeToTimeHive: () => "Bienvenido a TimeHive",
  welcomeToTimeHivePleaseUpdateInfo: () =>
    "Bienvenido a TimeHive. Por favor, actualiza tu información a continuación para continuar.",
  completeYourVisits: () => "Completa tus visitas",
  getStarted: () => "Empezar",
  noteExclamation: () => "¡Nota!",
  noSensitiveDataSaved: () =>
    "No guardamos información personal identificable. Este dato se utilizará para identificar tu perfil en nuestro sistema EHR.",
  resetSSN: () => "Restablecer SSN",
  switchProfile: () => "Cambiar Agencia",
  selectProfile: () => "Seleccionar Agencia",
  noProfiles: () => "Sin Agencias",
  noIncompleteVisits: () => "No hay Visitas Incompletas",
  login: () => "Iniciar sesión",
  loginToContinue: () => "Inicia sesión para continuar ahora.",
  reload: () => "Recargar",
  logout: () => "Cerrar sesión",
  youAreLoggedOut: () => "¡Has cerrado sesión!",
  loginAgainToUse: () => "Inicia sesión nuevamente para usar la Hoja de Tiempo",
  caregiverId: (caregiverId: string) => `ID del cuidador: ${caregiverId}`,
  "timesheets/days": (number?: number) => `${number ?? ""} hojas/días`,
  redirecting: () => "Redireccionando",
  redirectingWithExclamation: () => "¡Redireccionando!",
  sortByDay: () => "Ordenar por Día",
  selectByWeek: () => "Seleccionar por Semana",
  updateSelectedVisits: (numberOfVisits: number) =>
    `Actualizar ${numberOfVisits} Visita${numberOfVisits > 1 ? "s" : ""} Seleccionada${
      numberOfVisits > 1 ? "s" : ""
    }`,
  fetchingVisits: () => "Obteniendo Visitas",
  myIncompleteVisits: () => "Mis Visitas Incompletas",
  patientWithName: (patientName: string) => `Paciente: ${patientName}`,
  visitDateWithDate: (visitDate: string) => `Fecha de Visita: ${visitDate}`,
  timeVisitStarted: (time: string) => `Hora de Inicio de la Visita: ${time}`,
  timeVisitEnded: (time: string) => `Hora de Finalización de la Visita: ${time}`,
  notProvided: () => "No Provisto",
  totalAccumulatedTimeWithColon: () => `Tiempo Acumulado Total:`,
  hoursShort: () => "hrs",
  minutesShort: () => "min",
  theHiveAwaits: () => "La Colmena Espera",
  noTenantPleaseCheckLink: () =>
    "No se encontró inquilino. Por favor, verifica el enlace con tu departamento de TI.",
  waitToLoadTenant: () => "Por favor, espera mientras cargamos tu inquilino...",
  noSignatureFound: () => "No se encontró firma",
  typeHere: () => "Escribe aquí",
  sorryTryAgain: () => "Lo siento, inténtalo de nuevo",
  dialogs: {
    escapeOrClickButtonToClose: () =>
      "Presiona la tecla ESC o haz clic en el botón de abajo para cerrar",
    unauthorized: {
      warningUnauthorized: () => "¡Advertencia: Acceso No Autorizado!",
      details: () =>
        "No estás autorizado para acceder a esta página. Por favor, contacta a tu departamento de TI para obtener soporte.",
    },
    noTenant: {
      warningNoTenantFound: () => "¡Advertencia: No se Encontró Inquilino en la URL!",
      details: () =>
        "Se debe especificar un inquilino antes de continuar. Por favor, verifica el enlace con tu equipo de TI.",
      enterIdentifier: () => "Ingrese el ID del inquilino",
    },
    resetSSN: {
      ssn: () => "SSN",
      userIDNotFound: () => "ID de usuario no encontrado",
    },
    submitTimesheetOptions: {
      instructionsToSubmit: {
        part1: () => "Cuando hagas clic en",
        part2: () => "Confirmar",
        part3: () => "tu hoja de tiempo será enviada",
      },
      somethingWentWrong: () => "¡Oops! Algo salió mal durante el envío.",
      errorReference: (errorCode: string) => `Ref#: ${errorCode}`,
      downloadPDF: () => "¿Descargar una copia en PDF?",
      errorGeneratingPDF: () =>
        "Error al generar el PDF. \nPor favor, inténtalo de nuevo más tarde.",
      pleaseSelectPatient: () => "Por favor, selecciona un paciente",
      noMoreThan7Days: () => "Solo puedes enviar 7 días a la vez",
      caregiverCodeRequired: () => "Se requiere el número de empleado",
      unableToGeneratePDF: () => "No se puede Generar el PDF de la Hoja de Tiempo",
      areYouSureTheDataYouEnteredIsCorrect: () =>
        "¿Estás seguro de que los datos que ingresaste son correctos?",
    },
  },
  days: {
    sunday: () => "Domingo",
    monday: () => "Lunes",
    tuesday: () => "Martes",
    wednesday: () => "Miércoles",
    thursday: () => "Jueves",
    friday: () => "Viernes",
    saturday: () => "Sábado",
  },
  timesheet: {
    xDutiesAreRequiredInThisSection: (minimum) =>
      `Se requieren al menos ${minimum.toString()} responsabilidades en esta sección`,
    checkOffDaysPatientCannotSign: () =>
      "Please check off all days when the patient could not sign",
    hhaDutyForm: () => "FORMULARIO DE DEBERES DE AUXILIAR DE SALUD EN EL HOGAR",
    patientSignature: () => "Firma del Paciente",
    hhaSignature: () => "Firma del Auxiliar de Salud en el Hogar",
    signHere: () => "Firma aquí",
    patientUnableToSign: () => "PACIENTE INCAPAZ DE FIRMAR",
    whyPatientCannotSign: () => "Por favor, especifica por qué el paciente no puede firmar",
    whyWithQuestionMark: (): "¿Por qué?" => "¿Por qué?",
    noDuplicateDaysOrDates: () => "No se permiten días o fechas duplicadas",
    pleaseFillAllFields: () => "Por favor, completa todos los campos",
    atLeastOneLine: () => "Se requiere al menos una línea",
    atLeastOneVisit: () => "Por favor, selecciona al menos una visita para enviar.",
    dateAndTime: () => "FECHA Y HORA",
    timeCannotBeInFuture: () => "La hora no puede estar en el futuro",
    endingDayOfWeek: (endingDayOfWeek: string) => `Finalizando ${endingDayOfWeek}`,
    date: () => "Fecha",
    day: () => "Día",
    timeArrived: () => "Hora de Llegada al Paciente",
    timeLeft: () => "Hora de Salida del Paciente",
    totalHours: () => "Horas Totales Trabajadas",
    overnightShiftWithQuestionMark: () => "¿Turno Nocturno?",
    scheduledTime: (time: string) => `Programado: ${time}`,
    duties: () => "DEBERES",
    checkOffDuties: () => "Por favor, marca todos los deberes completados",
    information: () => "INFORMACIÓN",
    caregiverName: () => "Nombre del Empleado",
    socialSecurityNumber: () => "Número de Seguro Social",
    caregiverCode: () => "Número de Empleado",
    patientName: () => "Nombre del Paciente",
    phone: () => "Teléfono",
    year: () => "Año",
    patientAddress: () => "Dirección del Paciente",
    patientId: () => "ID del Paciente",
    timesheetReason: () => "Motivo de la hoja de horas",
    reason: () => "Razón",
    select: () => "Seleccionar",
    missing: {
      hhaSignature: () => "Falta la Firma del Auxiliar de Salud en el Hogar.",
      patientSignature: () => "Falta la Firma del Paciente.",
      patientId: () => "Falta el ID del Paciente.",
      patientName: () => "Falta el Nombre del Paciente.",
      patientAddress: () => "Falta la Dirección del Paciente.",
      caregiverName: () => "Falta el Nombre del Empleado.",
      caregiverCode: () => "Falta el Número de Empleado.",
      pocCompliance: () => "Deberes requeridos no marcados / POC Compliance",
      timesheetReason: () => "Falta el motivo del parte de horas.",
    },
    signInBoxBelow: () => "Por favor, firma en el recuadro de abajo",
  },
  errors: {
    caregiverIdRequired: () => "Se requiere el ID del cuidador",
    firstNameRequired: () => "Se requiere el nombre",
    lastNameRequired: () => "Se requiere el apellido",
    ssnRequired: () => "Se requiere el número de seguro social (SSN)",
    dayRequired: () => "Se requiere el día",
    dateRequired: () => "Se requiere la fecha",
    timeCannotBeInTheFuture: () => "La hora no puede ser en el futuro",
    totalHrsRequired: () => "Se requieren las horas totales trabajadas",
    greaterThan0: () => "Las horas totales trabajadas deben ser mayores que 0",
    lessThan24hrs: () => "Las horas totales trabajadas deben ser menores que 24 horas",
    mustBeInteger: () => "Las horas totales trabajadas deben ser un número entero",
    ssnFormat: () => "El SSN debe tener el formato 123-45-6789",
    ssnMustBe9Digits: () => "El SSN debe tener exactamente 9 dígitos",
    caregiverNameRequired: () => "Se requiere el nombre del empleado",
    caregiverCodeRequired: () => "Se requiere el número de empleado",
    patientNameRequired: () => "Se requiere el nombre del paciente",
    invalidYear: () => "Ingrese un año válido",
    patientAddressRequired: () => "Se requiere la dirección del paciente",
    patientIdRequired: () => "Se requiere el ID del paciente",
    invalidPatientId: () => "Ingrese un ID de paciente válido",
    phoneNumberRequired: () => "Se requiere el número de teléfono",
  },
  mainainance: {
    maintainanceInProgress: () => "Mantenimiento en curso",
    weAreApplyingUpgradesOperationalSoon: () =>
      "La Colmena se está limpiando y expandiendo, estaremos operativos pronto",
    thankYouForYourPatience: () => "Gracias por su paciencia",
    maintainanceWillBePerformed: (startTime, endTime) =>
      "TimeHive estará inoperativo para mantenimiento programado entre " +
      startTime +
      " y " +
      endTime,
  },
};

export default spanish;
