import { type ITranslation } from "../../types/types";

const russian: ITranslation = {
  tutorial: {
    stepOne: {
      newUsers: () => "Новые пользователи",
      existingUsers: () => "Существующие пользователи",
      clickOnGetStarted: () =>
        'Нажатие на "Начать" перенаправит вас на наш сервис аутентификации, Auth0.',
      newUserNextSteps: () => `После регистрации Auth0 перенаправит вас обратно на TimeHive.`,
      existingUserNextSteps: () =>
        `После входа в систему Auth0 перенаправит вас обратно на TimeHive. Если вы уже вошли в систему, вас сразу перенаправят обратно на TimeHive.`,
    },
    clickIcon: () => "Нажмите на свою иконку, чтобы открыть выпадающее меню.",
    useButtons: () =>
      "Используйте эти кнопки, чтобы помочь вам выбрать посещения для подтверждения.",
    clickToSelectVisits: () => "Кликните, чтобы выбрать посещения, которые вы хотите подтвердить.",
    proceedConfirm: () => "Перейдите к подтверждению выбранных посещений.",
    onlyRedAsterixRequired: () =>
      "Поля без красной звездочки необязательны и могут оставаться пустыми.",
    verifyYourInfo: () =>
      "Проверьте правильность ваших данных. Свяжитесь с администратором для изменений.",
    verifyPatientInfo: () =>
      "Проверьте правильность информации о пациенте. Свяжитесь с администратором для изменений.",
    verifyPatientAddressAndId: () => "Проверьте или введите адрес и ID пациента.",
    verifyDateTimes: () => "Проверьте дату и время посещения.",
    checkDuties: () =>
      "Отметьте выполненные обязанности для каждого дня (посещения), которые вы подтверждаете. В зависимости от вашего агентства, это может быть видно или нет.",
    mayNotBeVisible: () => "В зависимости от вашего агентства, это может быть видно или нет.",
    provideSignatures: () => "Предоставьте подпись для пациента и для себя.",
    checkDaysUnableToSign: () =>
      "Отметьте и объясните причину дней, когда пациент не смог подписаться.",
    clickSubmitData: () => "Нажмите, чтобы отправить введенные данные и подтвердить визиты.",
    downloadPDF: () => "Оставьте отмеченным, чтобы скачать PDF-копию вашего табеля.",
    finalSubmit: () =>
      "Назад пути нет! Нажмите здесь, чтобы отправить ваши данные и подтвердить ваш визит.",
  },
  twoCharCode: () => "ru",
  language: () => "Язык",
  languages: {
    eng: () => "Английский",
    spa: () => "Испанский",
    rus: () => "Русский",
    kor: () => "Корейский",
    yue: () => "Кантонский",
    cmn: () => "Китайский (путунхуа)",
    ben: () => "Бенгальский",
    ceb: () => "Себуано",
    yid: () => "идиш",
  },
  navigation: {
    home: () => "Главная",
    visits: () => "Посещения",
  },
  dropdown: {
    aboutToLeave: () => "Подождите, вы собираетесь уйти!",
    confirmLogout: () => "Вы уверены, что хотите выйти?",
  },
  actions: {
    ok: () => "Ок",
    cancel: () => "Отмена",
    clear: () => "Очистить",
    close: () => "Закрыть",
    remove: () => "Удалить",
    submit: () => "Отправить",
    save: () => "Сохранить",
    savingTrailOff: () => "Сохранение...",
    update: () => "Обновить",
    updatingTrailOff: () => "Обновление...",
    confirm: () => "Подтвердить",
    submittingTrailOff: () => "Отправка...",
    sync: () => "Синхронизация",
    syncTrailOff: () => "Синхронизация...",
    change: () => "Изменить",
    back: () => "Назад",
    done: () => "Готово",
    next: () => "рядом с",
  },
  welcomeToTimeHive: () => "Добро пожаловать в TimeHive",
  welcomeToTimeHivePleaseUpdateInfo: () =>
    "Добро пожаловать в TimeHive. Пожалуйста, обновите свою информацию ниже, чтобы продолжить",
  completeYourVisits: () => "Завершите ваши посещения",
  getStarted: () => "Начать",
  noteExclamation: () => "Заметка!",
  noSensitiveDataSaved: () =>
    "Мы не сохраняем личную идентифицируемую информацию. Этот ввод будет использоваться для идентификации вашего профиля в нашей системе электронных медицинских записей.",
  resetSSN: () => "Сбросить SSN",
  switchProfile: () => "Изменить агентство",
  selectProfile: () => "Выберите агентство",
  noProfiles: () => "Нет агентств",
  noIncompleteVisits: () => "Нет незавершенных посещений",
  login: () => "Войти",
  loginToContinue: () => "Войдите, чтобы продолжить сейчас.",
  reload: () => "Перезагрузить",
  logout: () => "Выйти",
  youAreLoggedOut: () => "Вы вышли!",
  loginAgainToUse: () => "Войдите снова, чтобы использовать Табель",
  caregiverId: (caregiverId: string) => `ID сиделки: ${caregiverId}`,
  "timesheets/days": (number?: number) => `${number ?? ""} табелей/дней`,
  redirecting: () => "Перенаправление",
  redirectingWithExclamation: () => "Перенаправление!",
  sortByDay: () => "Сортировать по дню",
  selectByWeek: () => "Выбрать по неделе",
  updateSelectedVisits: (numberOfVisits: number) =>
    `Обновить ${numberOfVisits} выбранных посещени${numberOfVisits > 1 ? "й" : "е"}`,
  fetchingVisits: () => "Получение посещений",
  myIncompleteVisits: () => "Мои незавершенные посещения",
  patientWithName: (patientName: string) => `Пациент: ${patientName}`,
  visitDateWithDate: (visitDate: string) => `Дата посещения: ${visitDate}`,
  timeVisitStarted: (time: string) => `Начало посещения: ${time}`,
  timeVisitEnded: (time: string) => `Окончание посещения: ${time}`,
  notProvided: () => "Не предоставлено",
  totalAccumulatedTimeWithColon: () => `Общее накопленное время:`,
  hoursShort: () => "ч",
  minutesShort: () => "мин",
  theHiveAwaits: () => "Улей ждет",
  noTenantPleaseCheckLink: () =>
    "Арендатор не найден. Пожалуйста, проверьте ссылку у вашего IT-отдела.",
  waitToLoadTenant: () => "Пожалуйста, подождите, пока мы загружаем ваш арендатор...",
  noSignatureFound: () => "Подпись не найдена",
  typeHere: () => "Введите здесь",
  sorryTryAgain: () => "Извините, попробуйте снова",
  dialogs: {
    escapeOrClickButtonToClose: () => "Нажмите клавишу ESC или щелкните кнопку ниже, чтобы закрыть",
    unauthorized: {
      warningUnauthorized: () => "Предупреждение: Несанкционированный доступ!",
      details: () =>
        "У вас нет разрешения на доступ к этой странице. Пожалуйста, свяжитесь с вашим IT-отделом для поддержки.",
    },
    noTenant: {
      warningNoTenantFound: () => "Предупреждение: Арендатор не найден в URL!",
      details: () =>
        "Арендатор должен быть указан перед продолжением. Пожалуйста, проверьте ссылку с вашей IT-командой.",
      enterIdentifier: () => "Введите идентификатор арендатора",
    },
    resetSSN: {
      ssn: () => "SSN",
      userIDNotFound: () => "ID пользователя не найден",
    },
    submitTimesheetOptions: {
      instructionsToSubmit: {
        part1: () => "Когда вы нажмете",
        part2: () => "Подтвердить",
        part3: () => "ваш табель времени будет опубликован",
      },
      somethingWentWrong: () => "Упс! Что-то пошло не так во время отправки.",
      errorReference: (errorCode: string) => `Ссылка#: ${errorCode}`,
      downloadPDF: () => "Загрузить копию в формате PDF?",
      errorGeneratingPDF: () => "Ошибка при создании PDF. \nПожалуйста, попробуйте еще раз позже.",
      pleaseSelectPatient: () => "Пожалуйста, выберите пациента",
      noMoreThan7Days: () => "Вы можете отправить только 7 дней за один раз",
      caregiverCodeRequired: () => "Требуется номер сотрудника",
      unableToGeneratePDF: () => "Не удалось создать PDF для Табеля времени",
      areYouSureTheDataYouEnteredIsCorrect: () => "Вы уверены, что введенные данные верны?",
    },
  },
  days: {
    sunday: () => "Воскресенье",
    monday: () => "Понедельник",
    tuesday: () => "Вторник",
    wednesday: () => "Среда",
    thursday: () => "Четверг",
    friday: () => "Пятница",
    saturday: () => "Суббота",
  },
  timesheet: {
    xDutiesAreRequiredInThisSection: (minimum) =>
      `В этом разделе требуется как минимум ${minimum.toString()} обязанностей`,
    checkOffDaysPatientCannotSign: () =>
      "Please check off all days when the patient could not sign",
    hhaDutyForm: () => "Форма обязанностей домашнего помощника",
    patientSignature: () => "Подпись пациента",
    hhaSignature: () => "Подпись домашнего помощника",
    signHere: () => "Подпишите здесь",
    patientUnableToSign: () => "ПАЦИЕНТ НЕ МОЖЕТ ПОДПИСАТЬ",
    whyPatientCannotSign: () => "Пожалуйста, укажите, почему пациент не может подписать",
    whyWithQuestionMark: (): "Почему?" => "Почему?",
    noDuplicateDaysOrDates: () => "Дублирование дней или дат не разрешено",
    pleaseFillAllFields: () => "Пожалуйста, заполните все поля",
    atLeastOneLine: () => "Требуется как минимум одна строка",
    atLeastOneVisit: () => "Пожалуйста, выберите хотя бы одно посещение для отправки.",
    dateAndTime: () => "Дата и время",
    timeCannotBeInFuture: () => "Время не может быть в будущем",
    endingDayOfWeek: (endingDayOfWeek: string) => `Завершение ${endingDayOfWeek}`,
    date: () => "Дата",
    day: () => "День",
    timeArrived: () => "Время прибытия к пациенту",
    timeLeft: () => "Время ухода от пациента",
    totalHours: () => "Общее количество часов работы",
    overnightShiftWithQuestionMark: () => "Ночная смена?",
    scheduledTime: (time: string) => `Запланировано: ${time}`,
    duties: () => "ОБЯЗАННОСТИ",
    checkOffDuties: () => "Пожалуйста, отметьте все выполненные обязанности",
    information: () => "ИНФОРМАЦИЯ",
    caregiverName: () => "Имя сотрудника",
    socialSecurityNumber: () => "Номер социального страхования",
    caregiverCode: () => "Номер сотрудника",
    patientName: () => "Имя пациента",
    phone: () => "Телефон",
    year: () => "Год",
    patientAddress: () => "Адрес пациента",
    patientId: () => "ID пациента",
    timesheetReason: () => "Причина расписания",
    reason: () => "Причина",
    select: () => "Выбирать",
    missing: {
      hhaSignature: () => "Отсутствует подпись домашнего помощника.",
      patientSignature: () => "Отсутствует подпись пациента.",
      patientId: () => "Отсутствует ID пациента.",
      patientName: () => "Отсутствует имя пациента.",
      patientAddress: () => "Отсутствует адрес пациента.",
      caregiverName: () => "Отсутствует имя сотрудника.",
      caregiverCode: () => "Отсутствует номер сотрудника.",
      pocCompliance: () => "Обязательные обязанности не отмечены / POC Compliance",
      timesheetReason: () => "Причина расписания отсутствует.",
    },
    signInBoxBelow: () => "Пожалуйста, подпишите в блоке ниже",
  },
  errors: {
    caregiverIdRequired: () => "Caregiver ID is required",
    firstNameRequired: () => "First name is required",
    lastNameRequired: () => "Last name is required",
    ssnRequired: () => "SSN is required",
    dayRequired: () => "Day is required",
    dateRequired: () => "Date is required",
    timeCannotBeInTheFuture: () => "Time cannot be in the future",
    totalHrsRequired: () => "Total hours worked is required",
    greaterThan0: () => "Total hours worked must be greater than 0",
    lessThan24hrs: () => "Total hours worked must be less than 24 hours",
    mustBeInteger: () => "Total hours worked must be an integer",
    ssnFormat: () => "SSN must be in the format of 123-45-6789",
    ssnMustBe9Digits: () => "SSN must be exactly 9 digits",
    caregiverNameRequired: () => "Employee name is required",
    caregiverCodeRequired: () => "Employee number is required",
    patientNameRequired: () => "Patient name is required",
    invalidYear: () => "Enter a valid year",
    patientAddressRequired: () => "Patient address is required",
    patientIdRequired: () => "Patient ID is required",
    invalidPatientId: () => "Enter a valid Patient ID",
    phoneNumberRequired: () => "Phone Number is required",
  },
  mainainance: {
    maintainanceInProgress: () => "Техническое обслуживание в процессе",
    weAreApplyingUpgradesOperationalSoon: () =>
      "Улей чистится и расширяется, мы скоро будем работать",
    thankYouForYourPatience: () => "Спасибо за ваше терпение",
    maintainanceWillBePerformed: (startTime, endTime) =>
      "TimeHive будет недоступен для запланированного обслуживания с " +
      startTime +
      " по " +
      endTime,
  },
};

export default russian;
