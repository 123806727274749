import { type PayloadAction, createSlice } from "@reduxjs/toolkit";
import { type TenantTaskType } from "../../services/schemas/tenant-task.schema";

export const TASKS_SLICE_NAME = "tasks";

export interface ITasksState<T> {
  data: Record<string, T>;
}

const initialState: ITasksState<TenantTaskType> = {
  data: {},
};
const tasksSlice = createSlice({
  name: TASKS_SLICE_NAME,
  initialState,
  reducers: {
    resetData: () => {
      return initialState;
    },
    setPartialData: (state, action: PayloadAction<typeof initialState>) => {
      return { ...state, ...action.payload };
    },
    setTenantTask: (state, action: PayloadAction<TenantTaskType>) => {
      const pdfFieldId = action.payload.pdfFieldId;
      state.data[pdfFieldId] = action.payload;
    },
    removeTenantTask: (state, action: PayloadAction<string>) => {
      const { [action.payload]: _, ...newData } = state.data;
      state.data = newData;
    },
  },
});

export const {
  setPartialData: setPartialDataAction,
  resetData: resetDataAction,
  setTenantTask: setTenantTaskAction,
  removeTenantTask: removeTenantAction,
} = tasksSlice.actions;
export default tasksSlice.reducer;
