import apiManagement from "../../configs/api-auth0-management.config";
import { type Auth0UserType } from "../schemas/auth0.user.schema";

export const getUser = async (id: string): Promise<Auth0UserType> => {
  const { data } = await apiManagement.get(`users/${id}`);
  return data;
};

export interface IUpdateUserArgs extends Partial<Auth0UserType> {
  userId: string;
}

export const updateUser = async (args: IUpdateUserArgs): Promise<unknown> => {
  const { userId, ...data } = args;
  const { data: updatedUser } = await apiManagement.patch(`users/${userId}`, data);
  return updatedUser;
};
