import { type PayloadAction, createSlice } from "@reduxjs/toolkit";

export const HAS_ERROR_SLICE_NAME = "has_error";

interface IHasErrorState {
  information: boolean;
  dateTime: boolean;
}

const initialState: IHasErrorState = {
  information: false,
  dateTime: false,
};

const hasErrorSlice = createSlice({
  name: HAS_ERROR_SLICE_NAME,
  initialState,
  reducers: {
    setPartialHasError: (state, action: PayloadAction<Partial<IHasErrorState>>) => {
      state = { ...state, ...action.payload };
      return state;
    },
  },
});

export const { setPartialHasError: setPartialHasErrorAction } = hasErrorSlice.actions;

export const hasErrorReducer = hasErrorSlice.reducer;
