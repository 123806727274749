import { type ITranslation } from "../../types/types";

const korean: ITranslation = {
  tutorial: {
    stepOne: {
      newUsers: () => "신규 사용자",
      existingUsers: () => "기존 사용자",
      clickOnGetStarted: () => '"시작"을 클릭하면 인증 서비스인 Auth0로 이동합니다.',
      newUserNextSteps: () => `가입 후에는 Auth0가 TimeHive로 리디렉션됩니다.`,
      existingUserNextSteps: () =>
        `로그인 후에는 Auth0가 TimeHive로 리디렉션됩니다. 이미 로그인되어 있으면 즉시 TimeHive로 리디렉션됩니다.`,
    },
    clickIcon: () => "아이콘을 클릭하여 드롭다운 메뉴를 엽니다.",
    useButtons: () => "이 버튼을 사용하여 확인할 방문을 선택하세요.",
    clickToSelectVisits: () => "확인하려는 방문을 선택하려면 클릭하세요.",
    proceedConfirm: () => "선택한 방문을 확인하려면 진행하세요.",
    onlyRedAsterixRequired: () => "빨간 별표가 없는 필드는 필수가 아니며 비어 있을 수 있습니다.",
    verifyYourInfo: () =>
      "귀하의 정보가 정확한지 확인하세요. 변경 사항이 있으면 관리자에게 문의하세요.",
    verifyPatientInfo: () =>
      "환자 정보가 정확한지 확인하세요. 변경 사항이 있으면 관리자에게 문의하세요.",
    verifyPatientAddressAndId: () => "환자의 주소와 ID를 확인하거나 입력하세요.",
    verifyDateTimes: () => "방문 날짜와 시간을 확인하세요.",
    checkDuties: () =>
      "확인하려는 각 날짜(방문)에 수행한 업무를 체크하세요. 기관에 따라 표시 여부가 다를 수 있습니다.",
    mayNotBeVisible: () => "기관에 따라 표시 여부가 다를 수 있습니다.",
    provideSignatures: () => "환자와 본인을 위한 서명을 제공하세요.",
    checkDaysUnableToSign: () => "환자가 서명할 수 없었던 날짜에 대한 이유를 체크하고 설명하세요.",
    clickSubmitData: () => "입력한 데이터를 제출하고 방문을 확인하려면 클릭하세요.",
    downloadPDF: () => "타임시트의 PDF 사본을 다운로드하려면 선택하세요.",
    finalSubmit: () => "되돌릴 수 없습니다! 데이터를 전송하고 방문을 확인하려면 여기를 클릭하세요.",
  },
  twoCharCode: () => "ko",
  language: () => "언어",
  languages: {
    eng: () => "영어",
    spa: () => "스페인어",
    rus: () => "러시아어",
    kor: () => "한국어",
    yue: () => "광동어",
    cmn: () => "중국어",
    ben: () => "벵골어",
    ceb: () => "세부아노",
    yid: () => "이디시",
  },
  navigation: {
    home: () => "홈",
    visits: () => "방문",
  },
  dropdown: {
    aboutToLeave: () => "잠깐, 당신은 떠나려고 합니다!",
    confirmLogout: () => "로그아웃 하시겠습니까?",
  },
  actions: {
    ok: () => "확인",
    cancel: () => "취소",
    clear: () => "지우기",
    close: () => "닫기",
    remove: () => "제거",
    submit: () => "제출",
    save: () => "저장",
    savingTrailOff: () => "저장 중...",
    update: () => "업데이트",
    updatingTrailOff: () => "업데이트 중...",
    confirm: () => "확인",
    submittingTrailOff: () => "제출 중...",
    sync: () => "동기화",
    syncTrailOff: () => "동기화 중...",
    change: () => "변경",
    back: () => "뒤로",
    done: () => "끝났다",
    next: () => "옆에",
  },
  welcomeToTimeHive: () => "TimeHive에 오신 것을 환영합니다",
  welcomeToTimeHivePleaseUpdateInfo: () =>
    "TimeHive에 오신 것을 환영합니다. 계속하려면 아래 정보를 업데이트해주세요",
  completeYourVisits: () => "방문을 완료하세요",
  getStarted: () => "시작하기",
  noteExclamation: () => "노트!",
  noSensitiveDataSaved: () =>
    "개인 식별 가능 정보는 저장하지 않습니다. 이 입력은 우리의 EHR 시스템에서 프로필을 식별하는 데 사용됩니다.",
  resetSSN: () => "SSN 재설정",
  switchProfile: () => "기관 변경",
  selectProfile: () => "기관 선택",
  noProfiles: () => "기관 없음",
  noIncompleteVisits: () => "미완료된 방문 없음",
  login: () => "로그인",
  loginToContinue: () => "계속하려면 로그인하세요.",
  reload: () => "새로 고침",
  logout: () => "로그아웃",
  youAreLoggedOut: () => "로그아웃되었습니다!",
  loginAgainToUse: () => "타임시트를 사용하려면 다시 로그인하세요",
  caregiverId: (caregiverId: string) => `간병인 ID: ${caregiverId}`,
  "timesheets/days": (number?: number) => `${number ?? ""} 타임시트/일`,
  redirecting: () => "리다이렉팅 중",
  redirectingWithExclamation: () => "리다이렉팅!",
  sortByDay: () => "날짜별 정렬",
  selectByWeek: () => "주별 선택",
  updateSelectedVisits: (numberOfVisits: number) => `${numberOfVisits}개 선택한 방문 업데이트`,
  fetchingVisits: () => "방문 가져오는 중",
  myIncompleteVisits: () => "내 미완료 방문",
  patientWithName: (patientName: string) => `환자: ${patientName}`,
  visitDateWithDate: (visitDate: string) => `방문 날짜: ${visitDate}`,
  timeVisitStarted: (time: string) => `방문 시작 시간: ${time}`,
  timeVisitEnded: (time: string) => `방문 종료 시간: ${time}`,
  notProvided: () => "제공되지 않음",
  totalAccumulatedTimeWithColon: () => `누적된 총 시간:`,
  hoursShort: () => "시간",
  minutesShort: () => "분",
  theHiveAwaits: () => "The Hive Awaits",
  noTenantPleaseCheckLink: () => "테넌트를 찾을 수 없습니다. IT 부서와 링크를 확인해주세요.",
  waitToLoadTenant: () => "테넌트를 로드하는 동안 기다려주세요...",
  noSignatureFound: () => "서명을 찾을 수 없음",
  typeHere: () => "여기에 입력하세요",
  sorryTryAgain: () => "미안합니다, 다시 시도해보세요",
  dialogs: {
    escapeOrClickButtonToClose: () => "닫으려면 ESC 키를 누르거나 아래 버튼을 클릭하세요",
    unauthorized: {
      warningUnauthorized: () => "경고: 권한 없음!",
      details: () => "이 페이지에 액세스할 권한이 없습니다. 지원을 위해 IT에 문의하세요.",
    },
    noTenant: {
      warningNoTenantFound: () => "경고: URL에 테넌트가 없습니다!",
      details: () => "계속하기 전에 테넌트를 지정해야 합니다. IT 팀과 링크를 확인해주세요.",
      enterIdentifier: () => "테넌트의 식별자를 입력하십시오",
    },
    resetSSN: {
      ssn: () => "SSN",
      userIDNotFound: () => "사용자 ID를 찾을 수 없음",
    },
    submitTimesheetOptions: {
      instructionsToSubmit: {
        part1: () => "클릭하면",
        part2: () => "확인",
        part3: () => "하여 타임시트가 게시됩니다",
      },
      somethingWentWrong: () => "이런! 제출 중에 문제가 발생했습니다.",
      errorReference: (errorCode: string) => `참조#: ${errorCode}`,
      downloadPDF: () => "PDF 복사본 다운로드?",
      errorGeneratingPDF: () => "PDF 생성 오류. \n나중에 다시 시도해주세요.",
      pleaseSelectPatient: () => "환자를 선택해주세요",
      noMoreThan7Days: () => "한 번에 최대 7일을 제출할 수 있습니다",
      caregiverCodeRequired: () => "직원 번호가 필요합니다",
      unableToGeneratePDF: () => "타임시트 PDF 생성이 불가능합니다",
      areYouSureTheDataYouEnteredIsCorrect: () => "입력한 데이터가 올바른지 확인하시겠습니까?",
    },
  },
  days: {
    sunday: () => "일요일",
    monday: () => "월요일",
    tuesday: () => "화요일",
    wednesday: () => "수요일",
    thursday: () => "목요일",
    friday: () => "금요일",
    saturday: () => "토요일",
  },
  timesheet: {
    xDutiesAreRequiredInThisSection: (minimum) =>
      `이 섹션에는 최소 ${minimum.toString()} 개의 업무가 필요합니다`,
    checkOffDaysPatientCannotSign: () =>
      "Please check off all days when the patient could not sign",
    hhaDutyForm: () => "홈 헬스 에이드 의무 양식",
    patientSignature: () => "환자 서명",
    hhaSignature: () => "홈 헬스 에이드 서명",
    signHere: () => "여기에 서명하세요",
    patientUnableToSign: () => "환자가 서명할 수 없음",
    whyPatientCannotSign: () => "환자가 서명할 수 없는 이유를 지정해주세요",
    whyWithQuestionMark: () => "왜?",
    noDuplicateDaysOrDates: () => "중복된 날짜나 일자는 허용되지 않습니다",
    pleaseFillAllFields: () => "모든 필드를 작성해주세요",
    atLeastOneLine: () => "적어도 한 줄을 필요로 합니다",
    atLeastOneVisit: () => "제출할 방문을 최소한 하나 선택해주세요.",
    dateAndTime: () => "날짜 및 시간",
    timeCannotBeInFuture: () => "시간은 미래일 수 없습니다",
    endingDayOfWeek: (endingDayOfWeek: string) => `${endingDayOfWeek} 종료`,
    date: () => "날짜",
    day: () => "요일",
    timeArrived: () => "환자 방문 도착 시간",
    timeLeft: () => "환자 방문 종료 시간",
    totalHours: () => "총 근무 시간",
    overnightShiftWithQuestionMark: () => "야간 근무?",
    scheduledTime: (time: string) => `예정: ${time}`,
    duties: () => "의무",
    checkOffDuties: () => "완료된 모든 의무에 체크해주세요",
    information: () => "정보",
    caregiverName: () => "직원 이름",
    socialSecurityNumber: () => "주민등록번호",
    caregiverCode: () => "직원 번호",
    patientName: () => "환자 이름",
    phone: () => "전화",
    year: () => "년도",
    patientAddress: () => "환자 주소",
    patientId: () => "환자 ID",
    timesheetReason: () => "작업표 이유",
    reason: () => "이유",
    select: () => "선택하다",
    missing: {
      hhaSignature: () => "홈 헬스 에이드 서명이 누락되었습니다.",
      patientSignature: () => "환자 서명이 누락되었습니다.",
      patientId: () => "환자 ID가 누락되었습니다.",
      patientName: () => "환자 이름이 누락되었습니다.",
      patientAddress: () => "환자 주소가 누락되었습니다.",
      caregiverName: () => "직원 이름이 누락되었습니다.",
      caregiverCode: () => "직원 번호가 누락되었습니다.",
      pocCompliance: () => "필수 업무가 체크되지 않음 / POC Compliance",
      timesheetReason: () => "작업표 이유가 누락되었습니다.",
    },
    signInBoxBelow: () => "아래 상자에 서명해주세요",
  },
  errors: {
    caregiverIdRequired: () => "যত্নকারী আইডি প্রয়োজন",
    firstNameRequired: () => "নাম প্রয়োজন",
    lastNameRequired: () => "শেষ নাম প্রয়োজন",
    ssnRequired: () => "এসএসএন প্রয়োজন",
    dayRequired: () => "দিন প্রয়োজন",
    dateRequired: () => "তারিখ প্রয়োজন",
    timeCannotBeInTheFuture: () => "সময় ভবিষ্যতে হতে পারে না",
    totalHrsRequired: () => "মোট ঘন্টা প্রয়োজন",
    greaterThan0: () => "মোট ঘন্টা 0 এর চেয়ে বড় হতে হবে",
    lessThan24hrs: () => "মোট ঘন্টা 24 এর কম হতে হবে",
    mustBeInteger: () => "মোট ঘন্টা একটি পূর্ণসংখ্যা হতে হবে",
    ssnFormat: () => "এসএসএন এই ফরম্যাটে হতে হবে 123-45-6789",
    ssnMustBe9Digits: () => "এসএসএন অবশ্যই 9 টি সংখ্যা হতে হবে",
    caregiverNameRequired: () => "কর্মচারীর নাম প্রয়োজন",
    caregiverCodeRequired: () => "কর্মচারী কোড প্রয়োজন",
    patientNameRequired: () => "রোগীর নাম প্রয়োজন",
    invalidYear: () => "একটি বৈধ বছর লিখুন",
    patientAddressRequired: () => "রোগীর ঠিকানা প্রয়োজন",
    patientIdRequired: () => "রোগী আইডি প্রয়োজন",
    invalidPatientId: () => "একটি বৈধ রোগী আইডি লিখুন",
    phoneNumberRequired: () => "ফোন নাম্বার প্রয়োজন",
  },
  mainainance: {
    maintainanceInProgress: () => "유지보수 진행 중",
    weAreApplyingUpgradesOperationalSoon: () =>
      "벌집이 정리되고 확장되고 있습니다. 곧 운영될 예정입니다",
    thankYouForYourPatience: () => "기다려 주셔서 감사합니다",
    maintainanceWillBePerformed: (startTime, endTime) =>
      "TimeHive는 " +
      startTime +
      "부터 " +
      endTime +
      "까지 예정된 유지보수로 인해 작동하지 않을 예정입니다",
  },
};

export default korean;
