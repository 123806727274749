import { useEffect } from "react";
import MaintenancePage from "../pages/MaintenancePage";
import { useMaintenance } from "../services/queries/maintenance.query";

interface IAuthCheckpointProviderProps {
  children: React.ReactNode;
}

const MaintenanceCheckpoint = ({ children }: IAuthCheckpointProviderProps) => {
  const { data, isLoading, refetch } = useMaintenance();
  useEffect(() => {
    if (!data?.id) void refetch();
  }, []);
  return isLoading ? null : data?.maintenanceActive ? <MaintenancePage /> : children;
};

export default MaintenanceCheckpoint;
