import { useContext, useEffect, useState } from "react";
import translations from "../helpers/translations";
import { LanguageContext } from "../context/LanguageContext";

function useTranslations() {
  const languageContext = useContext(LanguageContext);
  const [currentTranslations, setCurrentTranslations] = useState(
    translations[languageContext?.language ?? "eng"],
  );

  // Update translations when language context changes
  useEffect(() => {
    setCurrentTranslations(translations[languageContext?.language ?? "eng"]);
  }, [languageContext, translations]);

  return currentTranslations;
}

export default useTranslations;
