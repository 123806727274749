import { type ITranslation } from "../../types/types";

const cantonese: ITranslation = {
  tutorial: {
    stepOne: {
      newUsers: () => "新用戶",
      existingUsers: () => "現有用戶",
      clickOnGetStarted: () => "點擊“開始”將引導您到我們的身份驗證服務Auth0。",
      newUserNextSteps: () => `註冊後，Auth0將重定向您回到TimeHive。`,
      existingUserNextSteps: () =>
        `登錄後，Auth0將重定向您回到TimeHive。如果您已經登錄，則會立即重定向回TimeHive。`,
    },
    clickIcon: () => "點擊您的圖標以打開下拉菜單。",
    useButtons: () => "使用這些按鈕來幫助您選擇要確認的訪問。",
    clickToSelectVisits: () => "點擊選擇您希望確認的訪問。",
    proceedConfirm: () => "繼續確認所選訪問。",
    onlyRedAsterixRequired: () => "沒有紅色星號的字段不是必需的，可能為空。",
    verifyYourInfo: () => "驗證您的信息是否正確。如需更改，請聯繫管理員。",
    verifyPatientInfo: () => "驗證患者信息是否正確。如需更改，請聯繫管理員。",
    verifyPatientAddressAndId: () => "驗證或輸入患者的地址和ID。",
    verifyDateTimes: () => "驗證訪問的日期和時間。",
    checkDuties: () =>
      "為您確認的每一天（訪問）勾選您執行的職責。根據您的機構，這可能可見也可能不可見。",
    mayNotBeVisible: () => "根據您的機構，這可能可見也可能不可見。",
    provideSignatures: () => "為患者和您自己提供簽名。",
    checkDaysUnableToSign: () => "勾選並解釋患者無法簽名的任何天的原因。",
    clickSubmitData: () => "點擊提交您輸入的數據並確認您的訪問。",
    downloadPDF: () => "保持選中以下載您時間表的PDF副本。",
    finalSubmit: () => "無法回退！點擊此處發送您的數據並確認您的訪問。",
  },
  twoCharCode: () => "zh",
  language: () => "語言",
  languages: {
    eng: () => "英文",
    spa: () => "西班牙文",
    rus: () => "俄文",
    kor: () => "韓文",
    yue: () => "粵語",
    cmn: () => "普通話",
    ben: () => "孟加拉文",
    ceb: () => "宿霧話",
    yid: () => "意第緒語",
  },
  navigation: {
    home: () => "主頁",
    visits: () => "訪問",
  },
  dropdown: {
    aboutToLeave: () => "等一等，你即將離開！",
    confirmLogout: () => "你確定要登出嗎？",
  },
  actions: {
    ok: () => "確定",
    cancel: () => "取消",
    clear: () => "清除",
    close: () => "關閉",
    remove: () => "移除",
    submit: () => "提交",
    save: () => "保存",
    savingTrailOff: () => "保存中...",
    update: () => "更新",
    updatingTrailOff: () => "更新中...",
    confirm: () => "確認",
    submittingTrailOff: () => "提交中...",
    sync: () => "同步",
    syncTrailOff: () => "同步中...",
    change: () => "更改",
    back: () => "返回",
    done: () => "完成",
    next: () => "下一個",
  },
  welcomeToTimeHive: () => "歡迎來到TimeHive",
  welcomeToTimeHivePleaseUpdateInfo: () => "歡迎來到TimeHive，請更新下面的信息以繼續",
  completeYourVisits: () => "完成您的訪問",
  getStarted: () => "開始",
  noteExclamation: () => "注意！",
  noSensitiveDataSaved: () => "我們不保存個人身份信息。此輸入將用於識別您在我們的EHR系統中的檔案。",
  resetSSN: () => "重設SSN",
  switchProfile: () => "更改機構",
  selectProfile: () => "選擇機構",
  noProfiles: () => "沒有機構",
  noIncompleteVisits: () => "沒有未完成的訪問",
  login: () => "登錄",
  loginToContinue: () => "立即登錄以繼續。",
  reload: () => "重新載入",
  logout: () => "登出",
  youAreLoggedOut: () => "您已登出！",
  loginAgainToUse: () => "重新登錄以使用Timesheet",
  caregiverId: (caregiverId: string) => `照顧者ID：${caregiverId}`,
  "timesheets/days": (number?: number) => `${number ?? ""}個工作表/天`,
  redirecting: () => "正在重新導向",
  redirectingWithExclamation: () => "重新導向！",
  sortByDay: () => "按日期排序",
  selectByWeek: () => "按周選擇",
  updateSelectedVisits: (numberOfVisits: number) => `更新${numberOfVisits}個所選訪問`,
  fetchingVisits: () => "正在提取訪問",
  myIncompleteVisits: () => "我的未完成訪問",
  patientWithName: (patientName: string) => `患者：${patientName}`,
  visitDateWithDate: (visitDate: string) => `訪問日期：${visitDate}`,
  timeVisitStarted: (time: string) => `訪問開始時間：${time}`,
  timeVisitEnded: (time: string) => `訪問結束時間：${time}`,
  notProvided: () => "未提供",
  totalAccumulatedTimeWithColon: () => `累計時間：`,
  hoursShort: () => "小時",
  minutesShort: () => "分鐘",
  theHiveAwaits: () => "蜂巢等待",
  noTenantPleaseCheckLink: () => "找不到租戶。請與您的IT部門核實鏈接。",
  waitToLoadTenant: () => "請稍候，我們正在加載您的租戶...",
  noSignatureFound: () => "找不到簽名",
  typeHere: () => "在這裡輸入",
  sorryTryAgain: () => "對不起，再試一次",
  dialogs: {
    escapeOrClickButtonToClose: () => "按ESC鍵或單擊下面的按鈕來關閉",
    unauthorized: {
      warningUnauthorized: () => "警告：未經授權的訪問！",
      details: () => "您無權訪問此頁面。請聯繫您的IT支持。",
    },
    noTenant: {
      warningNoTenantFound: () => "警告：URL中找不到租戶！",
      details: () => "繼續之前必須指定租戶。請與您的IT團隊核實鏈接。",
      enterIdentifier: () => "輸入租戶的身份證",
    },
    resetSSN: {
      ssn: () => "SSN",
      userIDNotFound: () => "找不到用戶ID",
    },
    submitTimesheetOptions: {
      instructionsToSubmit: {
        part1: () => "當您點擊",
        part2: () => "確認",
        part3: () => "您的時間表將被發布",
      },
      somethingWentWrong: () => "哎呀！提交過程中出了些問題。",
      errorReference: (errorCode: string) => `參考#：${errorCode}`,
      downloadPDF: () => "下載PDF副本？",
      errorGeneratingPDF: () => "生成PDF時出錯。\n請稍後再試。",
      pleaseSelectPatient: () => "請選擇一個患者",
      noMoreThan7Days: () => "您一次只能提交7天",
      caregiverCodeRequired: () => "需要員工編號",
      unableToGeneratePDF: () => "無法生成時間表PDF",
      areYouSureTheDataYouEnteredIsCorrect: () => "您確定輸入的數據正確嗎？",
    },
  },
  days: {
    sunday: () => "星期日",
    monday: () => "星期一",
    tuesday: () => "星期二",
    wednesday: () => "星期三",
    thursday: () => "星期四",
    friday: () => "星期五",
    saturday: () => "星期六",
  },
  timesheet: {
    xDutiesAreRequiredInThisSection: (minimum) => `呢一部分至少需要 ${minimum.toString()} 項職責`,
    checkOffDaysPatientCannotSign: () =>
      "Please check off all days when the patient could not sign",
    hhaDutyForm: () => "家庭護理助手職責表",
    patientSignature: () => "患者簽名",
    hhaSignature: () => "家庭護理助手簽名",
    signHere: () => "在這裡簽名",
    patientUnableToSign: () => "患者無法簽名",
    whyPatientCannotSign: () => "請說明患者無法簽名的原因",
    whyWithQuestionMark: (): "為什麼？" => "為什麼？",
    noDuplicateDaysOrDates: () => "不允許重復日期或日子",
    pleaseFillAllFields: () => "請填寫所有字段",
    atLeastOneLine: () => "至少需要一行",
    atLeastOneVisit: () => "請至少選擇一次訪問進行提交。",
    dateAndTime: () => "日期和時間",
    timeCannotBeInFuture: () => "時間不能是未來的",
    endingDayOfWeek: (endingDayOfWeek: string) => `結束於${endingDayOfWeek}`,
    date: () => "日期",
    day: () => "日子",
    timeArrived: () => "抵達患者時間",
    timeLeft: () => "離開患者時間",
    totalHours: () => "總工作時間",
    overnightShiftWithQuestionMark: () => "過夜班？",
    scheduledTime: (time: string) => `預定：${time}`,
    duties: () => "職責",
    checkOffDuties: () => "請勾選已完成的所有職責",
    information: () => "信息",
    caregiverName: () => "員工姓名",
    socialSecurityNumber: () => "社會安全號碼",
    caregiverCode: () => "員工編號",
    patientName: () => "患者姓名",
    phone: () => "電話",
    year: () => "年份",
    patientAddress: () => "患者地址",
    patientId: () => "患者ID",
    timesheetReason: () => "時間表原因",
    reason: () => "原因",
    select: () => "選擇",
    missing: {
      hhaSignature: () => "缺少家庭護理助手簽名。",
      patientSignature: () => "缺少患者簽名。",
      patientId: () => "缺少患者ID。",
      patientName: () => "缺少患者姓名。",
      patientAddress: () => "缺少患者地址。",
      caregiverName: () => "缺少員工姓名。",
      caregiverCode: () => "缺少員工編號。",
      pocCompliance: () => "必要職責未勾選 / POC Compliance",
      timesheetReason: () => "缺少時間表原因。",
    },
    signInBoxBelow: () => "請在下方簽名框中簽名",
  },
  errors: {
    caregiverIdRequired: () => "護理員編號必填",
    firstNameRequired: () => "名字必填",
    lastNameRequired: () => "姓氏必填",
    ssnRequired: () => "社會安全編號必填",
    dayRequired: () => "日期必填",
    dateRequired: () => "日期必填",
    timeCannotBeInTheFuture: () => "時間不能為未來時間",
    totalHrsRequired: () => "總工時必填",
    greaterThan0: () => "總工時必須大於0",
    lessThan24hrs: () => "總工時必須少於24小時",
    mustBeInteger: () => "總工時必須為整數",
    ssnFormat: () => "社會安全編號必須為123-45-6789格式",
    ssnMustBe9Digits: () => "社會安全編號必須為9位數",
    caregiverNameRequired: () => "員工姓名必填",
    caregiverCodeRequired: () => "員工編號必填",
    patientNameRequired: () => "病人姓名必填",
    invalidYear: () => "請輸入有效年份",
    patientAddressRequired: () => "病人地址必填",
    patientIdRequired: () => "病人編號必填",
    invalidPatientId: () => "請輸入有效病人編號",
    phoneNumberRequired: () => "電話號碼必填",
  },
  mainainance: {
    maintainanceInProgress: () => "維護進行中",
    weAreApplyingUpgradesOperationalSoon: () => "蜂巢正在清潔和擴展，我們將很快恢復運作",
    thankYouForYourPatience: () => "感謝您的耐心",
    maintainanceWillBePerformed: (startTime, endTime) =>
      "TimeHive 將於 " + startTime + " 至 " + endTime + " 進行計劃維護",
  },
};

export default cantonese;
